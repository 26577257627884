import sendRequest from '../../libs/sendRequest'

export const losses = {
    namespaced: true,
    state: {
        losses: [],
        loss: {},
        error: null,
        total_pages: 0,
        total_rows: 0
    },
    getters: {
        getLosses(state) {
            return state.losses
        },
        getLoss(state){
            return state.loss
        },
        getError(state){
            return state.error
        },
        getTotalPages(state) {
            return state.total_pages
        },
        getTotalRows(state) {
            return state.total_rows
        }
    },
    mutations: {
        setLosses(state, payload){
            state.losses = payload
        },
        setLoss(state, payload) {
            state.loss = payload
        },
        setError(state, payload){
            state.error = payload
        },
        setTotalPages(state, payload) {
            state.total_pages = payload
        },
        setTotalRows(state, payload) {
            state.total_rows = payload
        }
    },
    actions: {
        async listLosses({commit, rootGetters}, payload){
            const custom_headers = rootGetters.getHeaders

            const query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: payload.search,
                order_by: 'id_reason_losses',
                order_asc: false,
            }

            const response = await sendRequest('catalog/reasonslosses/list', 'GET', null, null, query, custom_headers)

            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setLosses', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages ?? 0)
                    commit('setTotalRows', response.data.list.total_rows ?? 0) 
                }

                else {
                    commit('setLosses', response.data.list ?? [])
                }
    
                commit('setError', false)
            }

            if(!response.success && response.errors){
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        async viewLoss({ commit, rootGetters}, query){
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('catalog/reasonslosses/view', 'GET', null, null, query, custom_headers)

            //console.log('RESPONSE:', response)
            if(response.success && response.data) {
                commit('setLoss', response.data.view)
                console.log('updated')
            }

            if(!response.success && response.errors){
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        async addLoss({ commit, rootGetters}, body){

            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('catalog/reasonslosses/add', 'POST', null, body, null, custom_headers)

            //console.log('RESPONSE:', response)
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Elemento añadido exitosamente.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            if(!response.success && response.errors){
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_reason_losses_already_exists') {
                        messages.push('Error: elemento registrado anteriormente.')
                    }

                    else if(response.errors[error].code == 'error_inserting_reason_losses') {
                        messages.push('Error: error al intentar guardar la información.')
                    }
                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }
                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido, intente más tade.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }

        },
        async editLoss({ commit, rootGetters}, data){

            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('catalog/reasonslosses/edit', 'PUT', null, data.body, data.query, custom_headers)

            //console.log('RESPONSE:', response)
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Elemento editado exitosamente.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            if(!response.success && response.errors){
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_reason_losses_not_found') {
                        messages.push('Error: Elemento no encontrado')
                    }

                    else if(response.errors[error].code == 'error_reason_losses_already_exists') {
                        messages.push('Error: ya existe un elemento con el mismo nombre.')
                    }

                    else if(response.errors[error].code == 'error_updating_reason_losses') {
                        messages.push('Error: error al intentar actualizar la información.')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }

                }
                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido intente más tarde.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }

        },
        async deleteLoss({ commit, rootGetters}, query){

            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('catalog/reasonslosses/inactive', 'DELETE', null, null, query, custom_headers)

            //console.log('RESPONSE:', response)
            if(response.success && response.data) {
                
                commit('setAlert', {
                    open: true, 
                    message: 'Elemento eliminado exitosamente.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});

            }

            if(!response.success && response.errors){

                let messages = []

                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_inactivating_reason_losses') {
                        messages.push('Error: Error al intentar eliminar el elemento.')
                    }
                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }

                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido intente más tarde.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }

        }
    }
}