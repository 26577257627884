import sendRequest from '../../libs/sendRequest'

export const branch = {
    namespaced: true,
    state: {
        branches: [],
        branch: [],
        error: null,
        total_pages: null,
        total_rows: null
    },
    getters: {
        getBranches: state => {
            return state.branches
        },
        getBranch: state => {
            return state.branch
        },
        getError: state => {
            return state.error
        },
        getTotalPages(state) {
            return state.total_pages
        },
        getTotalRows(state) {
            return state.total_rows
        }
    },
    mutations: {
        setBranches: (state, payload) => {
            state.branches = payload
        },
        setBranch: (state,payload) => {
            state.branch = payload
        },
        setError: (state, payload) => {
            state.error = payload
        },
        setTotalPages(state, payload) {
            state.total_pages = payload
        },
        setTotalRows(state, payload) {
            state.total_rows = payload
        }
    },
    actions: {
        listBranches: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders

            const query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: payload.search,
                order_by: 'id_store',
                order_asc: false,
            }

            const response = await sendRequest('catalog/stores/list', 'GET', null, null, query, custom_headers)
            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setBranches', response.data.list.result ?? [] )
                    commit('setTotalPages', response.data.list.total_pages ?? 0)
                    commit('setTotalRows', response.data.list.total_rows ?? 0) 
                } else {
                    commit('setBranches', response.data.list ?? [] )
                }
                commit('setError', false)
            }
            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                commit('setError', true)
            }
            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API')
            }
        },
        viewBranch: async ({commit, rootGetters}, query) => {

            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('catalog/stores/view', 'GET', null, null, query, custom_headers)
           
            if(response.success && response.data) {
                commit('setBranch', response.data.view)
            }
            else if(!response.success && response.errors){
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                commit('setAlert', {
                    open: true, 
                    message: 'Un error ha ocurrido intente más tarde',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API')
            }
        },
        insertBranch: async ({commit, rootGetters}, body) => {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('catalog/stores/add', 'POST', null, body, null, custom_headers) 

            if(response.success && response.data) {
               
                commit('setAlert', {
                    open: true, 
                    message: 'Sucursal añadida con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            else if(!response.success && response.errors) {
                let messages = []

                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_store_name_already_exists') {
                        messages.push('Error: Ya existe una sucursal con ese nombre.')
                    }

                    else if(response.errors[error].code == 'error_store_email_already_exists') {
                        messages.push('Error: Ya existe una sucursal con ese correo.')
                    }

                    else if(response.errors[error].code == 'error_inserting_store') {
                        messages.push('Error: Error al intentar añadir la sucursal.')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }
                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }

            else if(response.success && !response.data) {
                console.log('Se  perdio la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        editBranch: async ({commit, rootGetters}, data) => {

            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('catalog/stores/edit', 'PUT', null, data.body, data.query, custom_headers) 

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Sucursal editada con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            else if(!response.success && response.errors) {
                let messages = []

                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_store_name_already_exists') {
                        messages.push('Error: Ya existe una sucursal con ese nombre.')
                    }

                    else if(response.errors[error].code == 'error_store_email_already_exists') {
                        messages.push('Error: Ya existe una sucursal con ese correo.')
                    }

                    else if(response.errors[error].code == 'error_updating_store') {
                        messages.push('Error: Error al editar la sucursal.')
                    }

                    else {
                       messages.push('Un error ha ocurrido intente más tarde.') 
                    }
                }

                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }

            else if(response.success && !response.data) {
                console.log('Se  perdio la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error ha ocurrido intente más tarde.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        deleteBranch: async ({commit, rootGetters}, query) => {

            const custom_headers = rootGetters.getHeaders
    
            const response = await sendRequest('catalog/stores/inactive', 'DELETE', null, null, query, custom_headers) 
    
            if(response.success && response.data) {
                //console.log('ELIMINADO EXITOSAMENTE')
                commit('setAlert', {
                    open: true, 
                    message: 'Sucursal eliminada con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }
    
            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_inactivating_store') {
                        messages.push('Error: Error al intentar eliminar la sucursal.')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }
                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
    
            else if(response.sucess && !response.data) {
                console.log('Se  perdio la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        }
    },
    
}