import sendRequest from '../../libs/sendRequest'

export const access_module = {
    namespaced: true,
    state:{
        access: []
    },
    mutations: {
        setAccessObject(state, payload) {
            state.access = payload
        }
    },
    getters: {
        getRequestedAccess (state){
            return state.access
        } 
    },
    actions: {
        async viewAccess(context, payload) {
            const custom_headers = context.rootGetters.getHeaders;

            const query = {
                id_rol: payload.id_rol
            }

            const response = await sendRequest('core/access/view', 'GET', null, null, query, custom_headers)
            console.log('response:', response)

            if(response.sucess && response.data) {
                context.commit('setAccessObject', response.data.view)
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdio conexión con la API')
            }
        }   
    }
}