import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
Vue.use(VueRouter)

// Modules
import Home from 'ROUTER/modules/home.js'
import Login from 'ROUTER/modules/login.js'
import Inventory from 'ROUTER/modules/inventory.js'
import Reports from 'ROUTER/modules/reports.js'
import Configuration from 'ROUTER/modules/configuration.js'
import Warehouses from 'ROUTER/modules/warehouses.js'
import Catalogues from 'ROUTER/modules/catalogues.js'

const routes = [
  	...Login,
  	...Home,
  	...Inventory,
  	...Reports,
  	...Configuration,
  	...Warehouses,
  	...Catalogues
  	//...Other,
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const only_with_session = ['sale', 'inventory', 'warehouses-menu', 'catalog-menu', 'reports', 'cashregister', 'cashbox', 'business', 'printedlabels', 'ticket' ]

const without_session = ['/']


router.beforeEach((to, from, next) => {

	//console.log('to:', to)
	//console.log('from:', from) 

  	const session = store.getters['getSession']
	console.log(session)
	
	if(session === null && only_with_session.includes(to.name)) {
		console.log('not allowed')
		next({
			name: 'login'
		})
	}

	if(session === null && !only_with_session.includes(to.name) && !without_session.includes(to.path)){
		console.log('not session, page doesnt exist')
		next({
			name: 'login'
		})
	}

	if(session && to.path === '/'){
		console.log('in session')
		next({
			name: 'sale'
		})
	}

	if(session && !only_with_session.includes(to.name)){
		console.log('pages doesnt exist')
		next({
			name: 'sale'
		})
	}
	

  	

	
  
  	next()
})

export default router
