export default [
    {
        path: '/Ventas',
        name: 'sale',
        meta: {
            layout: 'session'
        },
        component: () => import('../../views/Home.vue')
    }
]
