<template>
    <div id="app">
        <component :is="layout || 'section'">
            <router-view/>
        </component>
    </div>
</template>

<script>
    export default {
        name: 'app_main',
        computed: {
            layout() {
                return this.$route.meta.layout
            }
        },
        data() {
            return {
                
            }
        }
    }
</script>

<style lang="scss">
    #app {
        font-family: OpenSans-Regular;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        width: 100vw !important;
        text-align: left;
        color: #FFFFFF;

        /* width */
        ::-webkit-scrollbar {
            width: 5px;
            margin-right: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: transparent; 
            border-radius: 10px;
        }
         
        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #f1f1f1; 
            border-radius: 10px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #444; 
        }
    }
</style>

