import sendRequest from '../../libs/sendRequest'

export const user_roles = {
    namespaced: true,
    state: {
        user_roles: [],
        user_role: {},
        error: null,
        total_pages: 0,
        total_rows: 0
    },
    getters: {
        getUserRoles: state => {
            return state.user_roles
        },
        getUserRole: state => {
            return state.user_role
        },
        getError: state => {
            return state.error
        },
        getTotalPages(state) {
            return state.total_pages
        },
        getTotalRows(state) {
            return state.total_rows
        }
    },
    mutations: {
        setUserRoles: (state, payload) => {
            state.user_roles = payload
        },
        setUserRole: (state,payload) => {
            state.user_role = payload
        },
        setError: (state, payload) => {
            state.error = payload
        },
        setTotalPages(state, payload) {
            state.total_pages = payload
        },
        setTotalRows(state, payload) {
            state.total_rows = payload
        }
    },
    actions: {
        listUserRoles: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders

            const query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: payload.search,
                order_by: 'id_role',
                order_asc: false,
            }

            const response = await sendRequest('core/userroles/list', 'GET', null, null, query, custom_headers)
            if(response.success && response.data) {

                if(query.datatable) {
                    commit('setUserRoles', response.data.list.result  ?? [])
                    commit('setTotalPages', response.data.list.total_pages ?? 0)
                    commit('setTotalRows', response.data.list.total_rows ?? 0)   
                }
                else {
                    commit('setUserRoles', response.data.list ?? [])
                }
        
                
                commit('setError', false)
            }
            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                commit('setError', true)
            }
            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API')
            }
        },
        viewUserRole: async ({ commit, rootGetters}, id_user_role) => {

            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('core/userroles/view', 'GET', null, null, id_user_role, custom_headers)
            if(response.success && response.data) {
                commit('setUserRole', response.data.view)
            }

            else if(!response.success && response.errors){
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                commit('setToast', {
                    header: 'Error',
                    body: 'Un error interno ha ocurrido intente más tarde',
                    variant: 'danger',
                    open: true,
                    life: 3000
                }, { root: true})
                commit('setNotification', true, { root: true})

            }

            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API')
            }

        },
        insertUserRole: async ({ commit, rootGetters}, body) => {

            const custom_headers = rootGetters.getHeaders
            
            const response = await sendRequest('core/userroles/add', 'POST', null, body, null, custom_headers) 

            if(response.success && response.data) {
                //console.log('CREADO EXITOSAMENTE')
                commit('setAlert', {
                    open: true, 
                    message: 'Rol de usuario añadido con éxito',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_user_role_already_exists') {
                        messages.push('Error: Rol de usuario previamente registrado.')
                    }

                    else if(response.errors[error].code == 'error_actions_not_registered') {
                        messages.push('Error: Permisos no asignados.')
                    }

                    else if(response.errors[error].code == 'error_inserting_user_role') {
                        messages.push('Error: Error al guardar el rol')
                    }

                    else if(response.errors[error].code == 'error_inserting_rol_access') {
                        messages.push('Error: Error al guardar los permisos del rol.')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde')
                    }
                }
                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});

            }

            else if(response.sucess && !response.data) {
                console.log('Se  perdio la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Error: Un error ha ocurrido intente más tarde',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        editUserRole: async ({ commit, rootGetters}, data) => {

            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('core/userroles/edit', 'PUT', null, data.body, data.query, custom_headers) 

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Rol de usuario editado con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_user_role_not_found') {
                        messages.push('Error: Rol de usuario no encontrado.')
                    }

                    else if(response.errors[error].code == 'error_user_role_inactive') {
                        messages.push('Error: Rol de usuario inactivo.')
                    }

                    else if(response.errors[error].code == 'error_user_role_already_exists') {
                        messages.push('Error: Rol de usuario previamente registrado.')
                    }

                    else if(response.errors[error].code == 'error_updating_user_role') {
                        messages.push('Error: Error al editar el rol.')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }
                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});

            }

            else if(response.sucess && !response.data) {
                console.log('Se  perdio la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Error: Un error ha ocurrido intente más tarde.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        deleteUserRole: async ({ commit, rootGetters}, payload) => {

            const custom_headers = rootGetters.getHeaders

            const query = {
                "id_user_role": payload.role_id
            }
    
            const response = await sendRequest('core/userroles/inactive', 'DELETE', null, null, query, custom_headers) 
    
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Rol de usuario eliminado con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }
    
            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                commit('setAlert', {
                    open: true, 
                    message: 'Error: Un error ha ocurrido intente más tarde.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
    
            else if(response.success && !response.data) {
                console.log('Se  perdio la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Error: Un error ha ocurrido intente más tarde.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        }
    }
}