import sendRequest from '../../libs/sendRequest'

export const buy_order_module = {
    namespaced: true,
    state: {
        orders: null,
        order: null,
        search_result: null,
        total_pages: null,
        last_page: false,
        total_rows: null,
        error: null
    },
    getters: {
        getOrders(state) {
            return state.orders
        },
        getOrder(state) {
            return state.order
        },
        getSearchResult(state) {
            return state.search_result
        },
        getTotalPages(state) {
            return state.total_pages;
        },
        getTotalRows(state) {
            return state.total_rows;
        },
        getLastPage(state) {
            return state.last_page;
        },
        getError(state) {
            return state.error
        }
    },
    mutations: {
        setOrders(state, payload) {
            state.orders = payload
        },
        setOrder(state, payload) {
            state.order = payload
        },
        setSearchResult(state, payload) {
            state.search_result = payload
        },
        setTotalPages(state, payload) {
            state.total_pages = payload
        },
        setLastPage(state, payload) {
            state.last_page = payload
        },
        setTotalRows(state, payload) {
            state.total_rows = payload
        },
        setError(state, payload) {
            state.error = payload
        }
    },
    actions: {
        async listOrders({commit, rootGetters}, payload) {
            const custom_headers = rootGetters.getHeaders

            const query = {
                datatable: payload.datatable,
                only_pendings: payload.pending,
                page: payload.page,
                rows: payload.rows,
                search: payload.search,
                order_by: 'id_purchase_order',
                date: payload.date,
                id_vendor: payload.id_vendor,
                id_product: payload.id_product,
                order_asc: false,
            }
           
            const response = await sendRequest('inventory/purchaseorders/list', 'GET', null, null, query, custom_headers)

            //console.log('RESPONSE:', response)
            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setOrders', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages ?? 0)
                    commit('setTotalRows', response.data.list.total_rows ?? 0)
                }

                else {
                    commit('setOrders', response.data.list ?? [])
                }

                commit('setError', false)
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                commit('setOrders', [])
            }
        },
        async viewOrder(context, payload) {
            const custom_headers = context.rootGetters.getHeaders

            const query = {
                id_purchase_order: payload.id_purchase_order
            }

            const response = await sendRequest('inventory/purchaseorders/view', 'GET', null, null, query, custom_headers)
            //console.log('RESPONSE:), response
            if(response.success && response.data) {
                context.commit('setOrder', response.data.view)
            }

            if(!response.succes && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }
        },
        async addOrder({commit, rootGetters}, payload) {
            const custom_headers = rootGetters.getHeaders

            const body = {
                id_vendor: payload.id_vendor,
                id_transaction_type: payload.id_transaction_type,
                product_list: payload.product_list,
                subtotal: payload.subtotal,
                tax: payload.tax,
                total: payload.total,
                delivery_date: payload.delivery_date,
                delivery_conditions: payload.delivery_conditions,
                shipment_cost: payload.shipment_cost,
                shipment_taxes_cost: payload.shipment_taxes_cost,
                comments: payload.comments
            }

            const response = await sendRequest('inventory/purchaseorders/add', 'POST', null, body, null, custom_headers)
            //console.log('RESPONSE:', response)
            if(response.success && response.data) {
                //console.log('INSERT:', response.data)
                commit('setAlert', {
                    open: true, 
                    message: 'Orden de compra añadida',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            if(!response.success && response.errors) {
                let messages = []

                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_inserting_purchase_order') {
                        messages.push('Error: Error al intentar crea la orden de compra.')
                    }

                    else if(response.errors[error].code == 'error_product_not_found') {
                        messages.push('Error: Producto no encontrado.')
                    }

                    else if(response.errors[error].code == 'error_vendor_is_incorrect') {
                        messages.push('Error: No se permite una orden de compra con diferentes proveedores.')
                    }
                    
                    else {
                        messages.push('Un error ha ocurrido intente de nuevo más tarde.')
                    }
                }

                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
                
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }

        },
        async completeOrder({commit, rootGetters}, payload) {
            const custom_headers = rootGetters.getHeaders

            const query = {
                id_purchase_order: payload.id_purchase_order
            }

            const response = await sendRequest('inventory/purchaseorders/confirmreceipt', 'POST', null,null, query, custom_headers)
            
            if(response.success && response.data) {
                console.log('created')
                commit('setAlert', {
                    open: true, 
                    message: 'Orden completada con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_purchase_order_delivered') {
                        messages.push('Error: La orden de venta ya fue entregada.')
                    }

                    else if(response.errors[error].code == 'error_product_not_found') {
                        messages.push('Error: producto no encontrado')
                    }

                    else if(response.errors[error].code == 'error_vendor_is_incorrect') {
                        messages.push('Error: Las ordenes no pueden tener más de un provedor')
                    }

                    else if(response.errors[error].code == 'error_inserting_inventory' || response.errors[error].code == 'error_updating_inventory') {
                        messages.push('Error: Error al actualizar el inventario.')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }
                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }

            
        },
        async deleteOrder({commit, rootGetters}, payload) {
            const custom_headers = rootGetters.getHeaders

            const query = {
                id_purchase_order: payload.id_purchase_order
            }

            const response = await sendRequest('inventory/purchaseorders/inactive', 'DELETE', null, null, query, custom_headers)
            console.log('RESPONSE:', response)

            if(response.success && response.data) {
                console.log('DELETED:', response.data)
                commit('setAlert', {
                    open: true, 
                    message: 'Orden eliminada con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_purchase_order_delivered') {
                        messages.push('Error: La orden de compra ya fue entregada.')
                    }

                    else if(response.errors[error].code == 'error_inactivating_purchase_order') {
                        messages.push('Error: Error al intententar cancelar la orden')
                    }
                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }
                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }

            if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        async searchProduct(context, payload) {
            const custom_headers = context.rootGetters.getHeaders

            const query = {
                upc: payload.upc
            }

            const response = await sendRequest('catalog/products/search', 'GET', null, null, query, custom_headers)

            if(response.success && response.data) {
                context.commit('setSearchResult', response.data.view)
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                context.commit('setSearchResult', { not_found: true})
            }

            if(response.success && !response.data) {
                console.log('se ha perdido la conexión con la API')
            }
        }
    }
}