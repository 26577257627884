import sendRequest from "../../libs/sendRequest"

export const product_transfers_module = {
    namespaced: true,
    state: {
        inventory: null,
        warehouse: null,
        error: null,
        total_pages: 0,
        total_rows: 0
    },
    getters: {
        getInventory (state) {
            return state.inventory
        },
        getWarehouse (state) {
            return state.warehouse
        },
        getError (state) {
            return state.error
        },
        getTotalPages (state) {
            return state.total_pages
        },
        getTotalRows (state) {
            return state.total_rows
        }
    },
    mutations: {
        setInventory(state, payload){
            state.inventory = payload
        },
        setWarehouse (state, payload) {
            state.warehouse = payload
        },
        setError(state, payload) {
            state.error = payload
        },
        setTotalPages(state, payload) {
            state.total_pages = payload
        },
        setTotalRows(state, payload) {
            state.total_rows = payload
        }
    },
    actions: {
        async listInventoryProducts (context, payload){
            const custom_headers = context.rootGetters.getHeaders

            const query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: payload.search,
                order_by: 'id_inventory',
                order_asc: false,
            }

            const response = await sendRequest('catalog/inventories/list', 'GET', null, null, query, custom_headers)
            if(response.success && response.data) {

                if(query.datatable) {
                    context.commit('setInventory', response.data.list.result ?? [])
                    context.commit('setTotalPages', response.data.list.total_pages ?? 0)
                    context.commit('setTotalRows', response.data.list.total_rows ?? 0)
                }

                else {
                    context.commit('setInventory', response.data.list ?? [])
                }

                context.commit('setError', false)
            }
            if(!response.success && response.errors){
                for(let error in response.errors) {
                    console.log(response.errors[error])

                    switch(response.errors[error].code) {
                        case 'error_inventory_not_found':
                            context.commit('setInventory', [])
                            return
                    }
                }
            }
            else if(response.success && !response.data) {
                //console.log('Se perdio la conexión con la API)
            }
        },
        async listWarehouseProducts (context, payload) {

            const custom_headers = context.rootGetters.getHeaders 

            const query = {
                id_distribution_center: payload.id_distribution_center
            }

            const response = await sendRequest('catalog/inventoriesassing/list', 'GET', null, null, query , custom_headers)
            if(response.success && response.data) {
                context.commit('setWarehouse', response.data.list)
            }
            if(!response.success && response.errors){
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }

                context.commit('setWarehouse', [])
            }
            else if(response.success && !response.data) {
                //console.log('Se perdio la conexión con la API)
            }
        },
        async toWarehouse ({commit, rootGetters}, payload) {

            const custom_headers = rootGetters.getHeaders 

            const body = {
                "id_distribution_center": payload.distribution_center,
                "inventories": payload.inventories
            }

            const response = await sendRequest('inventory/transfers/todc', 'POST', null, body, null, custom_headers)
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Traspaso realizado con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
                
            }

            if(!response.success && response.errors){
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_distribution_center_not_found') {
                        messages.push('Error: Almacén no encontrado.')
                    }

                    else if(response.errors[error].code == 'error_distribution_center_inactive') {
                        messages.push('Error: Almacén no inactivo.')
                    }

                    else if(response.errors[error].code == 'error_inventory_not_found') {
                        messages.push('Error: Inventario no encontrado')
                    } 
                    
                    else if(response.errors[error].code == 'error_inventory_inactive') {
                        messages.push('Error: Inventario inactivo')
                    } 
                    
                    else if(response.errors[error].code == 'error_quantity_available_is_not_sufficient') {
                        messages.push('Error: Insuficiente cantidad en inventario.')
                    }

                    else {
                        messages.push('Un error interno a sucedido intente más tarde.')
                    }
                }

                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }

            else if(response.success && !response.data) {
                //console.log('Se perdio la conexión con la API)
                commit('setAlert', {
                    open: true, 
                    message: 'Error: Un error de conexión ha ocurrido, intente nuevamente.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        async betweenWarehouse ({commit, rootGetters}, payload) {

            const custom_headers = rootGetters.getHeaders 

            const body = {
                id_distribution_center: payload.distribution_center,
                assings: payload.assings
            }

            const response = await sendRequest('inventory/transfers/betweendc', 'POST', null, body, null, custom_headers)
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Traspaso realizado con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
                
            }
            if(!response.success && response.errors){
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_distribution_center_not_found' ) {
                        messages.push('Error: Almacén no encontrado.')
                    }

                    else if(response.errors[error].code == 'error_distribution_center_inactive' ) {
                        messages.push('Error: Almacén inactivo.')
                    }

                    else if(response.errors[error].code == 'error_inventory_not_found' ) {
                        messages.push('Error: Inventario no encontrado.')
                    }

                    else if(response.errors[error].code == 'error_inventory_inactive' ) {
                        messages.push('Error: Inventario inactivo.')
                    }

                    else if(response.errors[error].code == 'error_quantity_available_is_not_sufficient' ) {
                        messages.push('Error: Insuficiente cantindad en inventario.')
                    }

                    else {
                        messages.push('Error: Un interno ha ocurrido intente más tarde.')
                    }
                }

                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
            else if(response.success && !response.data) {
                //console.log('Se perdio la conexión con la API)
                commit('setAlert', {
                    open: true, 
                    message: 'Error: Un error de conexión ha ocurrido, intente nuevamente.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        }
    }

}