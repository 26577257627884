import sendRequest from "../../libs/sendRequest"

export  const inventories_module = {
    namespaced: true,
    state: { 
        inventories: [],
        inventory: {},
        error: null
    },
    getters: {
        getInventories(state) {
            return state.inventories
        },
        getInventory(state) {
            return state.inventory
        },
        getError(state) {
            return state.error
        }
    },
    mutations: {
        setInventories(state, payload) {
            state.inventories = payload
        },
        setInventory(state,payload) {
            state.inventory = payload
        },
        setError(state, payload) {
            state.error = payload
        }
    },
    actions: {
        async listInventories(context) {
            
            const custom_headers = context.rootGetters.getHeaders

            const response = await sendRequest('catalog/inventories/list', 'GET', null, null, null, custom_headers)
            //console.log('INVENTORY LIST:',response)
            if(response.success && response.data) {
                context.commit('setInventories', response.data.list)
                context.commit('setError', false)
            }

            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                context.commit('setError', true)
            }

            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
            }
        },
        async viewInventory (context, payload) {

            const custom_headers = context.rootGetters.getHeaders

            const query = {
                'id_inventory': payload.id
            }
            const response = await sendRequest('catalog/inventories/view', 'GET', null, null, query, custom_headers)
            //console.log('INVENTORY VIEW:', response)
            if(response.success && response.data) {
                context.commit('setInventory', response.data.view)
            }
            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(error)
                }
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
            }
        },
        async insertInventory(context, payload) {

            const custom_headers = context.rootGetters.getHeaders

            const body = {
                "quantity": payload.quantity,
                "comments": payload.comments
            }
            
            const query = {
                "id_product": payload.product_id
            }

            const response = await sendRequest('catalog/inventories/add', 'POST', null, body, query, custom_headers)
            //console.log('INVENTORY ADD', response)
            if(response.success && response.data) {
                console.log('Creado exitosamente')
                context.commit('setToast',{
                    open: true,
                    variant: 'success',
                    header: 'Success',
                    body: 'Producto añadido exitosamente',
                    life: 3000
                }, { root: true})
                context.commit('setNotification', true, { root: true})
            }
            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                console.log('Creado exitosamente')
                context.commit('setToast',{
                    open: true,
                    variant: 'danger',
                    header: 'Error',
                    body: 'Un error ha ocurrido intente más tarde',
                    life: 3000
                }, { root: true})
                context.commit('setNotification', true, { root: true})
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
            }
        },
        async editInventory (context, payload) {
            const custom_headers = context.rootGetters.getHeaders

            const body = {
                "quantity": payload.quantity,
	            "comments": payload.comments,
	            "id_product": payload.product_id,
	            "active": payload.active
            }

            const query = {
                'id_inventory': payload.id_inventory
            }
            const response = await sendRequest('catalog/inventories/edit', 'PUT', null, body, query, custom_headers)
            //console.log('INVENTORY EDIT:', response)
            if(response.success && response.data) {
                context.commit('setToast',{
                    open: true,
                    variant: 'success',
                    header: 'Success',
                    body: 'Editado Correctamente',
                    life: 3000
                }, { root: true})
                context.commit('setNotification', true, { root: true})
            }

            else if(!response.success && response.errors) {
                context.commit('setToast',{
                    open: true,
                    variant: 'danger',
                    header: 'Error',
                    body: 'Un error ha ocurrido',
                    life: 3000
                }, { root: true})
                context.commit('setNotification', true, { root: true})
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
            }
        },
        async deleteInventory (context, payload) {

            const custom_headers = context.rootGetters.getHeaders

            const query = {
                'id_inventory': payload.inventory_id
            }

            const response = await sendRequest('catalog/inventories/inactive', 'DELETE', null, null, query, custom_headers)
            //console.log('INVENTORY DELETE:', response)
            if(response.success && response.data) {
                console.log('eliminado exitosamente')
                context.commit('setToast',{
                    open: true,
                    variant: 'success',
                    header: 'Success',
                    body: 'Eliminado exitosamente',
                    life: 3000
                }, { root: true})
                context.commit('setNotification', true, { root: true})
            }
            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
            }
        }
    }
}