import sendRequest from '../../libs/sendRequest'

export const changes_module = {
    namespaced: true,
    state: {
        sale: [],
    },
    mutations: {
        setSale: (state, payload) => {
            state.sale = payload
        }
    },
    getters: {
        getSale: (state) => state.sale
    },
    actions: {
        async viewChange({context,rootGetters}, payload) {

            const custom_headers = rootGetters.getHeaders;

            const query = {
                id_sale: payload.id_sale
            }

            const response = await sendRequest('change/search_sale', 'GET', null, null, query, custom_headers)
            //console.log('response:', response)
            if(response.success && response.data) {
                commit('setSale', response.data.search)
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        }
    },
    async processChange({commit, rootGetters}, payload) {
        const custom_headers = rootGetters.getHeaders;

        const body = {

        }

        const response = await sendRequest('/change/sale', 'POST', null, body, null, custom_headers);
    
        if(response.success && response.data) {
            commit('setAlert', {
                open: true, 
                message: 'Cambio realizado con éxito',
                variant: 'success',
                text_color: 'white',
            }, { root: true});
        }

        if(!response.success && response.errors) {
            for(let error in response.errors) {
                console.log(response.errors[error])
            }

            commit('setAlert', {
                open: true, 
                message: 'Un error de conexión ha ocurrido intente más tarde.',
                variant: 'danger',
                text_color: 'white',
            }, { root: true});
            commit('setSale', true)
        }

        if(response.success && !response.data) {
            console.log('Se perdio la conexión con la API')
            commit('setAlert', {
                open: true, 
                message: 'Un error de conexión ha ocurrido.',
                variant: 'danger',
                text_color: 'white',
            }, { root: true});
        }
    }
}