import Vue from 'vue'
import Vuex from 'vuex'
import sendRequest from '../libs/sendRequest'
import AES from '../libs/AES'
import router from '../router'

const base64 = require('base-64')
const utf8 = require('utf8')

//modules
import { category } from './modules/category'
import { subcategory } from './modules/subcategory'
import { warehouse } from './modules/warehouses'
import { branch } from './modules/branch'
import { providers } from './modules/providers'
import { companies_module } from './modules/company'
import { employees } from './modules/employees'
import { users } from './modules/users' 
import { user_roles } from './modules/userRoles'
import { movements } from './modules/movements'
import { transaction_types } from './modules/transactionType'
import { inventories_module } from './modules/inventories'
import { inventories_assigns_module } from './modules/inventoryAssign'
import { products_module } from './modules/products'
import { products_discounts_module } from './modules/productsDiscounts'
import { discounts } from './modules/discounts'
import { product_transfers_module } from './modules/productTransfers'
import { clients } from './modules/clients'
import { box_operations_module } from './modules/boxOperations'
import { losses } from './modules/losses'
import { buy_order_module } from './modules/buyOrder'
import { pricing_module } from './modules/pricing'
import { lockbox_module } from './modules/lockbox'
import { access_module } from './modules/access'
import { changes_module } from './modules/change'
import { reports } from './modules/reports'
import { companies } from './modules/company'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        show_label: true,
        loged: null,
        alert: {
            open: false,
            message: 'Error',
            variant: 'danger',
            color_text: 'white',
            timeout: null,
        },
        session: null,
        access: [],
    },
    getters: {
        getSession: state => {
            state.session = sessionStorage.getItem('kinver@session')
            //console.log('BEFORE:', state.session)
            try {
                state.session = AES.decrypt(Buffer.from(state.session, 'base64'))
                //console.log('AES:', state.session)
                state.session = JSON.parse(state.session)
                //console.log('SESSION:',state.session)
            }
            catch(error) {
                state.session = null   
                console.log(error)
            }
            return state.session
        },
        getHeaders: state => {
            state.session = sessionStorage.getItem('kinver@session')
            let headers = {
                ...CONFIG.server[CONFIG.env].headers, 
                ...{ session: state.session } 
            }
            return headers
        },
        getLogin: state => {
            return state.loged
        },
        getAccess: state => {
            let access_encrypt = localStorage.getItem('kinver@access')
            let access = JSON.parse(AES.decrypt(Buffer.from(access_encrypt, 'base64')))
            return access
        },
        getAlert: (state) => (state.alert)
    },
    mutations: {
        toggleLabels (state) {
            state.show_label = !state.show_label
        },
        setSession: (state, new_data) => {
            state.session = new_data
            sessionStorage.setItem('kinver@session', new_data)
        },
        setLogin(state, payload) {
            state.loged = payload
        },
        setAlert: (state, new_alert) => {
            state.alert.open = new_alert.open
			state.alert.message = (new_alert.message !== undefined) ? new_alert.message : ''
			state.alert.variant = (new_alert.variant !== undefined) ? new_alert.variant : 'success'
			state.alert.text_color = (new_alert.text_color !== undefined) ? new_alert.text_color : 'white'
			state.alert.timeout = (new_alert.timeout !== undefined) ? new_alert.timeout : 3000

			if (state.alert.timeout !== null) {
				setTimeout(() => {
					state.alert.open = false
				}, state.alert.timeout)
			}
        },
        setAccess: (state, payload) => {
            let access = JSON.stringify(payload)
            let access_encrypt = AES.encrypt(access)
            localStorage.setItem('kinver@access', access_encrypt)
        }
    },
    actions: {
        createSession: ({commit}, session_data) => {
            commit('setSession', session_data)
        },
        existSession: (state) => {
            state.session = sessionStorage.getItem('kinver@session')
            try {
                state.session = base64.decode(state.session)
                state.session = JSON.parse(state.session)
            }
            catch(e) {
                state.session = null   
            }
            return state.session
        },
        deleteSession: ({commit}) => {
            delete CONFIG.server[CONFIG.env].headers.session;
            commit('setSession', null)
        },
        login: async (context, payload) => {
            const response = await sendRequest('account/login', 'POST', null, payload)
            console.log(response)
            if(response.success && response.data) {
                context.commit('setAccess', response.data.menu)
                context.dispatch('createSession', response.data.session)
                context.commit('setLogin', true)
                context.commit('setAlert', {
                    open: true, 
                    message: 'Bienvenido',
                    variant: 'success',
                    text_color: 'white',
                });

                setTimeout(() => {
                    router.push('sales')
                }, 1000);
            
            }
            else if(!response.success && response.errors) {
                for(let error in response.errors){
                    console.log(error)
                }
                context.commit('setAlert', {
                    open: true, 
                    message: 'Error: Contraseña o usuario incorrecto.',
                    variant: 'danger',
                    text_color: 'white',
                });
            }
            else if(response.success && !response.data) {
                console.log('Se perdio la conexion con el API')
                context.commit('setAlert', {
                    open: true, 
                    message: 'Error: Un error ha ocurrido intente más tarde.',
                    variant: 'danger',
                    text_color: 'white',
                });
            }
        }
    },
    modules: {
        category,
        subcategory,
        warehouse,
        branch,
        providers,
        companies,
        employees,
        users,
        movements,
        user_roles,
        transaction_types,
        inventories_module,
        inventories_assigns_module,
        products_module,
        products_discounts_module,
        discounts,
        product_transfers_module,
        clients,
        box_operations_module,
        losses,
        buy_order_module,
        pricing_module,
        lockbox_module,
        access_module,
        changes_module,
        reports
    }
})
