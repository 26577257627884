import sendRequest from '../../libs/sendRequest'

export const box_operations_module = {
    namespaced: true,
    state: {
        status: null,
        sale_complete: null,
        cashbox_data: null,
        search_result: null
    },
    getters: {
        getStatus(state){
            return state.status
        },
        getSale(state) {
            return state.sale_complete
        },
        getSearchResult(state) {
            return state.search_result
        },
        getCashboxdata(state) {
            return state.cashbox_data
        }
    },
    mutations: {
        setStatus(state, payload) {
            state.status = payload
        },
        setSale(state, payload) {
            state.sale_complete = payload
        },
        setSearchResult(state, payload) {
            state.search_result = payload
        },
        setCashboxData(state, payload) {
            state.cashbox_data = payload
        }
    },
    actions: {
        async checkStatus (context) {
            const custom_headers = context.rootGetters.getHeaders

            const response = await sendRequest('cashbox/status', 'GET', null, null, null, custom_headers)

            if(response.success && response.data) {
                context.commit('setStatus', response.data)
            }

            if(!response.success && response.errors ) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            else if (response.success && !response.data) {
                console.log('Se perdio conexión con la API')
            }
        },
        async checkCashbox (context) {
            const custom_headers = context.rootGetters.getHeaders

            const response = await sendRequest('report/cashbox/get_my_cashbox_data', 'GET', null, null, { datatable: false}, custom_headers)

            if(response.success && response.data) {
                context.commit('setCashboxData', response.data)
            }

            if(!response.success && response.errors ) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            else if (response.success && !response.data) {
                console.log('Se perdio conexión con la API')
            }
        },
        async openBox ({commit, rootGetters}, payload) {
            const custom_headers = rootGetters.getHeaders

            const body = {
                password: payload.password,
                comments:''
            }

            const response = await sendRequest('cashbox/open', 'POST', null, body, null, custom_headers)
            //console.log('RESPONSE:', response)

            if(response.success && response.data) {
                //console.log('STATUS:', response.data)
                commit('setStatus', response.data)
                localStorage.setItem('kinver@box', JSON.stringify(response.data))
                commit('setAlert', {
                    open: true, 
                    message: 'Caja abierta con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            if(!response.success && response.errors ) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_password_invalid') {
                        messages.push('Error: Password  incorrecto.')
                    }

                    else if(response.errors[error].code == 'cashbox_cannot_open') {
                        messages.push('Error: No se pudo abrir la caja.')
                    }

                    else if(response.errors[error].code == "error_user_no_found") {
                        messages.push('Error: Usuario no encontrado.')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }

                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }

            else if (response.success && !response.data) {
                console.log('Se perdio conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        async closeBox ({commit, rootGetters}, payload) {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest(`cashbox/close/${payload.query.id_cashbox}`, 'POST', null, payload.body, payload.query, custom_headers)
            //console.log('RESPONSE:', response)

            if(response.success && response.data) {
                //console.log('STATUS:', response.data)
                commit('setStatus', response.data)
                commit('setAlert', {
                    open: true, 
                    message: 'Caja cerrada con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            if(!response.success && response.errors ) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_password_invalid') {
                        messages.push('Error: Contraseña incorrecta.')
                    }

                    else if(response.errors[error].code == 'cashbox_aleady_close') {
                        messages.push('Error: Caja cerrada')
                    }

                    else if(response.errors[error].code == "error_deposit_amount_incorrect") {
                        messages.push('Error: La cantidad total de la caja no coincide')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }
                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }

            else if (response.success && !response.data) {
                console.log('Se perdio conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error ha ocurrido intente más tarde',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        async sale({ commit, dispatch, rootGetters}, payload) {
            const custom_headers = rootGetters.getHeaders

            const body = {
                product_list: payload.product_list,
                discount_manual: payload.discount_manual,
                subtotal: Number(payload.subtotal),
                iva: payload.iva,
                total: payload.total,
                payment: payload.payment,
                is_reserve: payload.is_reserve,
                id_client: payload.client_id
            }

            if(payload.in_pricing) {
                body.id_pricing = payload.id_pricing
            }
            
            const response = await sendRequest('sale/reserve/add', 'POST', null, body, null, custom_headers)
            console.log('response:', response)
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Venta realizada con exito',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
                commit('setSale', true)
            }

            if(!response.success && response.errors) {
                let messages = []
                for (let error in response.errors) {
                    switch(response.errors[error].code) {
                        case "error_product_not_available":
                            await dispatch('products_module/viewProduct', { id_product: response.errors[error].detail.id_product}, {root:true});
                            let product = rootGetters['products_module/getProduct']
                            messages.push(`${product.product_name} no disponible`)
                            break;
                        case 'error_updating_inventory_assign':
                            messages.push('Error: Error al actualizar el inventario.')
                            break;
                        case 'error_inserting_sale_item':
                            messages.push('Error: Error al procesar la venta.')
                            break;
                        default:
                            messages.push('Ha ocurrido un error intente más tarde')
                            break;
                    }

                    commit('setAlert', {
                        open: true, 
                        message: 'Un error ha ocurrido intente más tarde',
                        variant: 'danger',
                        text_color: 'white',
                    }, { root: true});


                }
                
            }       
        },
        async searchProduct(context, payload) {
            const custom_headers = context.rootGetters.getHeaders

            let query 
            if(payload.type == 'upc') {
                query = {
                    search_type: 'upc',
                    upc: payload.upc
                }
            }

            else {
                query = {
                    search_type: 'product_name',
                    product_name: payload.product_name
                }
            }

            const response = await sendRequest('sale/search_product', 'GET', null, null, query, custom_headers)

            if(response.success && response.data) {
                context.commit('setSearchResult', response.data.search)
            }

            if(!response.success && response.errors) {
                let messages = ''
                for( let error in response.errors) {
                    console.log(response.errors[error])
                    if(response.errors[error].code === "error_there_is_no_quantity_of_product_in_your_dc") {
                        messages = 'No hay existencias en esta sucursal';
                    }

                    else if(response.errors[error].code === 'error_product_not_found') {
                        messages = 'El producto no existe';
                    }


                }
                context.commit('setSearchResult', { not_found: messages })
            }

            if(response.success && !response.data) {
                console.log('se perdio la conexión con la API')
            }
        },
        async pricing({commit, rootGetters}, payload) {

            const custom_headers = rootGetters.getHeaders

            const body = {
                product_list: payload.product_list,
                total: payload.total,
                email: payload.email,
                iva: payload.iva,
                subtotal: Number(payload.subtotal),
            }

            

            const response = await sendRequest( 'sale/pricing/add', 'POST', null, body, null, custom_headers)
            console.log('response:', response)
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Cotización enviada con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
                commit('setSale', true)
            }

            if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].message == 'error_inserting_pricing_item'){
                        messages.push('Error: error al intentar procesar la cotización')
                    }
                    else {
                        messages.push('Un error ha ocurrido intente más tarde')
                    }
                }

                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
                commit('setSale', true)
            }
        }
    }
}