<template>
    <div class="w-100 overflow-hidden p-0 m-0">
        <p class="bg-primary text-white pl-2 d-flex justify-content-between" 
            style="border-radius: 3px;">
            {{ label }}

            <button @click="open = !open;" 
                class="bg-transparent border-0"
                style="z-index: 10;"
                type="button">
                    <img :src="toggle_down_icon" width="25px" v-if="!open"/>
            </button>

            <button @click="open = !open;" v-if="open"
                class="bg-transparent border-0"
                style="z-index: 10;"
                type="button">
                    <img :src="toggle_up_icon" width="25px"/>
            </button>
        </p>
                                
        <div v-show="open">
            <div class="w-100 d-flex flex-column align-items-start">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import toggle_down_icon from 'ASSETS/icons/icon_down.png'
    import toggle_up_icon from 'ASSETS/icons/icon_top.png'

    export default {
        data(){
            return {
                open: false,
                toggle_down_icon,
                toggle_up_icon,
            }
        },
        props: {
            label: {
                type: String,
                required: false,
                default: 'Placeholder'
            }
        }
    }
</script>