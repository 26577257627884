export default [
    {
        path: '/Almacenes',
        name: 'warehouses-menu',
        meta: {
            layout: 'session'
        },
        component: () => import('../../views/Warehouses.vue')
    }
]
