<template>
    <section class="alert_container" v-show="data_alert.open">
        <section :class="`alert ${data_alert.variant}  fade-in`"
            v-for="(message, i_message) in messages" :key="`alert-${i_message}`"
                :style="`animation-duration: ${200 * (i_message + 1)}ms;`">

            <div>
                <span :class="`ge--description-xs opensans-bold text-${data_alert.text_color} pt-3`">
                    {{ message }}
                </span>
            </div>

            <div>
                <span :class="`closebtn all_transition text-${data_alert.text_color}`">
                    &times;
                </span>
            </div>
        </section>
    </section>
</template>

<script>
    export default {
        computed: {
            data_alert(){
                let data  = this.$store.getters['getAlert']
                return data;
            },
            messages() {
                if(Array.isArray(this.data_alert.message)) {
                    return this.data_alert.message;
                }

                else {
                    return new Array(this.data_alert.message)
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    .alert_container {
        max-width: 550px;
        width: 100%;
        position: fixed;
        right: 10px;
        top: 10px;
        z-index: 10000;
    }

    .alert {
        border-radius: 4px;
        padding: 10px;
        width: 100%;
        display: flex;
        place-content: center space-between;

        div {
            .closebtn {
                font-size: 24px;
            }

            .closebtn:hover {
                opacity: 1;
            }
        }
        
    }

    .alert.primary { background-color: #1C355E; }
    .alert.secondary { background-color: #ced4da; }
    .alert.success { background-color: #00B560; }
    .alert.warning { background-color: #ffc107; }
    .alert.danger  { background-color: #dc3545; }
    .alert.info { background-color: #0dcaf0; }
    .alert.light { background-color: #f8f9fa; }
    .alert.dark { background-color: #212529; }


    .fade-in {
        opacity: 1;
        animation-name: fadeInOpacity;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
    }

    @keyframes fadeInOpacity {
        0% { 
            opacity: 0;
            transform: translateX(200px);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

</style>