import sendRequest from "../../libs/sendRequest"

export  const subcategory = {
    namespaced: true,
    state: { 
        subcategories: [],
        subcategory: {},
        error: null,
        total_pages: 0,
        total_rows: 0
    },
    getters: {
        getSubcategories(state) {
            return state.subcategories
        },
        getSubcategory(state) {
            return state.subcategory
        },
        getError(state) {
            return state.error
        },
        getTotalPages(state) {
            return state.total_pages
        },
        getTotalRows(state) {
            return state.total_rows
        }
    },
    mutations: {
        setSubcategories(state, payload) {
            state.subcategories = payload
        },
        setSubcategory(state,payload) {
            state.subcategory = payload
        },
        setError(state, payload) {
            state.error = payload
        },
        setTotalPages(state, payload) {
            state.total_pages = payload
        },
        setTotalRows(state, payload) {
            state.total_rows = payload
        }
    },
    actions: {
        async listSubcategories ({commit, rootGetters}, payload) {
            const custom_headers = rootGetters.getHeaders

            const query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: payload.search,
                order_by: 'id_sub_category',
                order_asc: false,
            }

            const response = await sendRequest('catalog/subcategories/list', 'GET', null, null, query, custom_headers)
            //console.log('SUBCATEGORY LIST:',response)
            if(response.success && response.data) {

                if(query.datatable) {
                    commit('setSubcategories', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages ?? 0)
                    commit('setTotalRows', response.data.list.total_rows ?? 0)  
                }

                else {
                    commit('setSubcategories', response.data.list ?? [])
                }
                
                commit('setError', false)
            }
            else if(!response.success && respose.errors) {
                for(let error in response.errors) {
                    console.log(error)
                }
                commit('setError', true)
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
            }
        },
        async viewSubcategory ({commit, rootGetters}, payload) {
            const custom_headers = rootGetters.getHeaders

            const query = {
                'id_subcategory': payload.subcategory_id
            }
            const response = await sendRequest('catalog/subcategories/view', 'GET', null, null, query, custom_headers)
            //console.log('SUBCATEGORY VIEW:', response)
            if(response.success && response.data) {
                commit('setSubcategory', response.data.view)
            }
            else if(!response.succes && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
            }
        },
        async insertSubcategory ({commit, rootGetters}, body) {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('catalog/subcategories/add', 'POST', null, body, null, custom_headers)
            //console.log('SUBCATEGORY ADD', response)
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Subcategoria añadida con éxito',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }
            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_category_not_found') {
                        messages.push('Error: Categoria no encontrada.')
                    }
                    else if(response.errors[error].code == 'error_category_inactive') {
                        messages.push('Error: no se permite asociar subcategorias a categorias inactivas.')
                    }
                    else if(response.errors[error].code == 'error_subcategory_already_exists') {
                        messages.push('Error: subcategoria registrada con anterioridad.')
                    }
                    else if(response.errors[error].code == 'error_inserting_subcategory') {
                        messages.push('Error: Un error ha ocurrido al procesar la operación, intente nuevamente.')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }

                }
                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Error: Un error de conexión ha ocurrido, intente más tarde.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        async editSubcategory ({ commit, rootGetters}, data) {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('catalog/subcategories/edit', 'PUT', null, data.body, data.query, custom_headers)
            //console.log('SUBCATEGORY EDIT:', response)
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Subcategoria editada con éxito',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }
            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code = 'error_category_not_found') {
                        messages.push('Error: categoria no encontrada.')
                    }
                    else if(response.errors[error].code = 'error_category_inactive') {
                        messages.push('Error: No se permite la edición de elementos inactivos.')
                    }
                    else if(response.errors[error].code = 'error_subcategory_not_found') {
                        messages.push('Error: Subcategoria no encontrada.')
                    }

                    else if(response.errors[error].code = 'error_subcategory_already_exists') {
                        messages.push('Error: subcategoria con mismo nombre, registrada con anterioridad.')
                    }

                    else if(response.errors[error].code = 'error_updating_subcategory') {
                        messages.push('Error: Edición fallida, error interno.')
                    }

                    else {
                        messages.push('Error: Un error ha ocurrido intente más tarde.')
                    }
                }
                
                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Error: Un error de conexión ha ocurrido, intente más tarde.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        async deleteSubcategory ({ commit, rootGetters}, payload) {
            const custom_headers = rootGetters.getHeaders

            const query = {
                'id_subcategory': payload.subcategory_id
            }

            const response = await sendRequest('catalog/subcategories/inactive', 'DELETE', null, null, query, custom_headers)
            //console.log('SUBCATEGORY DELETE:', response)
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Subcategoria elminada con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }
            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                commit('setAlert', {
                    open: true, 
                    message: 'Error: Un error ha ocurrido intente más tarde.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
            else if(response.success && response.data === null) {
                commit('setAlert', {
                    open: true, 
                    message: 'Error: Un error de conexión ha ocurrido, intente más tarde.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        }
    }
}