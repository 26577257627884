var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"input_container"},[(_vm.label !== '')?_c('span',{class:`input_label d-flex
        ${(_vm.theme == 'dark') ? 'text-white' : 'text-dark'}`},[_c('div',{staticClass:"float-lg-left mr-3",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_vm._t("label"),_vm._v(" "),_c('br')],2):_vm._e(),_c('input',_vm._g(_vm._b({class:[
            `input-thm-${_vm.theme} input-${_vm.variant}`, 
            (_vm.full) ? 'full-width' : '',
            (_vm.border_color === null) ? '': `border-${_vm.border_color}`,
            (_vm.rounded) ? 'btn-pill': ''
        ],attrs:{"id":_vm._id,"placeholder":_vm.placeholder,"type":_vm.type,"name":_vm.name,"autocomplete":_vm.autocomplete,"max":_vm.max},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),_vm.listeners))])
}
var staticRenderFns = []

export { render, staticRenderFns }