module.exports = {
	env: "prod",
	server: {
		local: {
			page_url: 'http://localhost:8080',
			api: 'http://localhost:3005',
			headers: {
				'Accept': 'application/json, text/plain, /',
            	'Content-Type': 'application/json',
				'Authorization': 'Bearer ba+BldFH/xgFGmIV+Z4+RwqkoVDihXcuieFGEgoTLDYAUintVGh4s59feYNimYYCMeF8ZAGi0d7E/omUkXH2+A=='
			}
		},
		dev: {
			page_url: 'https://qa.kinver.com',
			api: 'https://api.kinver.app',
			headers: {
				'Accept': 'application/json, text/plain, /',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ba+BldFH/xgFGmIV+Z4+RwqkoVDihXcuieFGEgoTLDYAUintVGh4s59feYNimYYCMeF8ZAGi0d7E/omUkXH2+A=='
			}
		},
		prod: {
			page_url: 'https://kinver.app/',
			api: 'https://api.kinver.app',
			headers: {
				'Accept': 'application/json, text/plain, /',
            	'Content-Type': 'application/json',
            	'Authorization': 'Bearer ba+BldFH/xgFGmIV+Z4+RwqkoVDihXcuieFGEgoTLDYAUintVGh4s59feYNimYYCMeF8ZAGi0d7E/omUkXH2+A=='
			}
		}
	},
	colors_theme: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark', 'white', 'lightblue' ],
}