<template>
    <section class="stepper">
        <div id="stepper__row" class="stepper__row"> 
            
            <!-- Externa -->
            <div v-for="c in steps" :key="c" 
                :class="`stepper__step all-transitions-middle
                ${current_step == c ? 'stepper__step--current' : ''}
                ${current_step > c ? 'stepper__step--complete' : ''}`">
                    
                <!-- Interna -->
                <div :class="`stepper__step--fill all-transitions-middle
                    ${current_step == c ? 'stepper__step--fill-current' : ''}
                    ${current_step > c ? 'stepper__step--fill-complete' : ''}`">

                    <!-- Imagen -->
                    <img :src="success" 
                        class="img-fluid"
                        v-if="current_step > c">
                    
                </div>

            </div>

            <div class="stepper__lines">
                <hr :id="`line_${i}`"
                    v-for="i in steps" :key="i" 
                    :class="`stepper__line
                            ${current_step > i ? 'stepper__line--current' : ''}`"
                    v-show="i !== steps.length" /> 
            </div>
            

        </div>

    </section>
</template>

<script>
    import success from 'ASSETS/icons/check_icon.png'
   export default {
        name: 'ki-stepper',
        data() {
            return {
                success
            };
        },
        props: {
            steps: {
                require: false,
                type: Array,
                default: [],
                validator: function (value) {
                    return !(value.length > 10 || value.length < 2)
                }
            },
            current_step: {
                required: true,
                type: Number,
                default: () => {
                    return 1
                }
            }
        },
        methods: {
            resizeLines() {
                for(let c in this.steps) {

                let diss = 0
                let left_help = 0

                switch(this.steps.length) {
                    case 2:
                        diss = 21
                        left_help = 288
                    break;
                    case 3:
                        diss = 25
                        left_help = 288
                    break;
                    case 4:
                        diss = 27
                        left_help = 192
                    break;
                    case 5:
                        diss = 27
                        left_help = 144
                    break;
                    case 6:
                        diss = 27
                        left_help = 115
                    break;
                    case 7:
                        diss = 27
                        left_help = 96
                    break;
                    case 8:
                        diss = 28
                        left_help = 82
                    break;
                    case 9:
                        diss = 28
                        left_help = 72
                    break;
                    case 10:
                        diss = 28
                        left_help = 64
                    break;
                    default:
                        diss = 28
                        left_help = 64
                    break;
                }

                let w = ($('#stepper__row').width() - 40) / (this.steps.length - 1) - diss
                let left = 30 + (left_help * (c - 1))
                let top = 0 - (18 * (c - 1))

                $(`#line_${c}`).css("width", w)
                $(`#line_${c}`).css("left", left)
                $(`#line_${c}`).css("top", top)
                }
            }
        },
        mounted () {
            window.addEventListener("resize", this.resizeLines)
            this.resizeLines()
        },
        onBeforeUnmount() {
            window.removeEventListener("resize", this.resizeLines)
        }

   }
</script>

<style lang="scss" scoped>

    .stepper {
        width: 100%;
        padding: 10px 0px;

        &__row {
            display: flex;
            justify-content: space-between;
        }

        &__step {
            display: flex;
            justify-content: center;
            align-items: center;
            
            border: 2px solid #dee2e6;
            border-radius: 100%; 
            height: 32px; 
            width: 32px;

            &--current {
                border: 2px solid #4F46E5 !important;
            }
            &--complete {
                border: 2px solid #4F46E5 !important;
                background-color: #4F46E5 !important;
            }

            &--fill {
                background-color: white;
                border-radius: 100%;
                height: 10px; 
                width: 10px;

                &-current {
                    background-color: #4F46E5;
                }

                &-complete {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    background-color: #4F46E5;
                    height: 60%; 
                    width: 60%;
                }
            }
        }

        &__lines {
            position: absolute

        }

        &__line {
            position: relative;
            top: 0px;
            left: 31px;
            height: 2px;
            background-color: white; 
            opacity: 1;

            -webkit-transition: color 0.5s;
            -moz-transition: color 0.5s;
            -ms-transition: color 0.5s;
            -o-transition: color 0.5s;
            transition: color 0.5s;

            &--current {
                background-color: #4F46E5 !important; 
            }
        }
    }
</style>