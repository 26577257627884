import sendRequest from '../../libs/sendRequest'

export const pricing_module = {
    namespaced: true,
    state: {
        pricing: null,
        pricings: null,
        process_pricing: {
            status: false,
            id_pricing: null
        },
        pages: null,
        last_page: false
    },
    getters: {
        getPricing(state) {
            return state.pricing
        },
        getPricings(state) {
            return state.pricings
        },
        getProcessPricing(state) {
            return state.process_pricing
        },
        getPages(state) {
            return state.pages
        },
        getLastPage(state) {
            return state.last_page
        }
    },
    mutations: {
        setPricing(state, payload) {
            state.pricing = payload
        },
        setPricings(state, payload) {
            state.pricings = payload
        },
        setProcessPricing(state, payload) {
            state.process_pricing = payload
        },
        setPages(state, payload) {
            state.pages = payload
        },
        setLastPage(state, payload) {
            state.last_page = payload
        }
    },
    actions: {
        async listPricings(context, payload) {

            const custom_headers = context.rootGetters.getHeaders

            const query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: '',
                order_by: 'creation',
                order_asc: false
            }

            if(payload.email) {
                query.email = payload.email
            }

            const response = await sendRequest( 'sale/pricing/list', 'GET', null, null ,query, custom_headers)
            
            if(response.success && response.data) {
                context.commit('setPricings', response.data.list.result )
                context.commit('setPages', response.data.list.total_pages)

                if(response.data.list.result == null) return

                if(context.state.pricings.length < payload.rows) {
                    context.commit('setLastPage', true)
                }

                else {
                    context.commit('setLastPage', false)
                }
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }

        },
        async viewPricing(context, payload) {

            const custom_headers = context.rootGetters.getHeaders

            const query = {
                id_pricing: payload.id_pricing
            }

            const response = await sendRequest('sale/pricing/view', 'GET', null, null, query, custom_headers)
            console.log('view:', response)
            if(response.success && response.data) {
                context.commit('setPricing', response.data.view)
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }
        },
        async deletePricing(context, payload) {

            const custom_headers = context.rootGetters.getHeaders

            const query = {
                id_pricing: payload.id_pricing
            }

            const response = await sendRequest('', 'DELETE', null, null, query, custom_headers)

            if(response.success && response.data) {

                context.commit('setToast', {
                    header: 'Success',
                    body: 'Cotización cancelada correactamente',
                    variant: 'success',
                    open: true,
                    life: 3000
                }, { root: true})
                context.commit('setNotification', true, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }

        },
        async searchPricing(context, payload) {

            const custom_headers = context.rootGetters.getHeaders

            const query = {

            }

            const response = await sendRequest('', 'GET', null, null, query, custom_headers)

            if(response.success && response.data){
                context.commit('setPricings', response.data.list)
            }

            if(!response.success && response.errors) {
                for(let error in response.errors){
                    console.log(response.errors[error])
                }
            }
            
        }
    }
}