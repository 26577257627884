import sendRequest from '../../libs/sendRequest'

export const movements = {
    namespaced: true,
    state: {
        movements: [],
        movement: {},
        error: null,
        total_pages: 0,
        total_rows: 0
    },
    getters: {
        getMovements: state => {
            return state.movements
        },
        getMovement: state => {
            return state.movement
        },
        getError: state => {
            return state.error
        },
        getTotalPages(state) {
            return state.total_pages
        },
        getTotalRows(state) {
            return state.total_rows
        }
    },
    mutations: {
        setMovements: (state, payload) => {
            state.movements = payload
        },
        setMovement: (state,payload) => {
            state.movement = payload
        },
        setError: (state, payload) => {
            state.error = payload
        },
        setTotalPages(state, payload) {
            state.total_pages = payload
        },
        setTotalRows(state, payload) {
            state.total_rows = payload
        }
    },
    actions: {
        listMovements: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders

            const query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: '',
                order_by: 'id_movement',
                order_asc: false,
            }

            const response = await sendRequest('catalog/movement/list', 'GET', null, null, query, custom_headers)
            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setMovements', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages ?? 0)
                    commit('setTotalRows', response.data.list.total_rows ?? 0) 
                }

                else {
                    commit('setMovements', response.data.list ?? [])
                }
        
                commit('setError', false)
            }
            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                commit('setError', true)
            }
            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API')
            }
        },
        viewMovement: async ({commit, rootGetters}, query) => {

            const custom_headers = rootGetters.getHeaders
            const response = await sendRequest('catalog/movement/view', 'GET', null, null, query, custom_headers)
            if(response.success && response.data) {
                commit('setMovement', response.data.view)
            }
            else if(!response.success && response.errors){
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }
            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API')
            }
        },
        insertMovement: async ({ commit, rootGetters}, body) => {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('catalog/movement/add', 'POST', null, body, null, custom_headers) 

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Movimiento añadido con éxito',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_already_exist_movement') {
                        messages.push('Error: nombre de movimiento registrado previamente.')
                    }

                    else if(response.errors[error].code == 'error_prefix_already_exist_prefix') {
                        messages.push('Error: prefijo de movimiento registrado previamente.')
                    }

                    else if(response.errors[error].code == 'error_added_movement') {
                        messages.push('Error: error al procesar la información')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }

                }
                
                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }

            else if(response.success && !response.data) {
                console.log('Se  perdio la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        editMovement: async ({commit, rootGetters}, data) => {

            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('catalog/movement/edit', 'PUT', null, data.body, data.query, custom_headers) 

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Movimiento editado con exito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_movement_name_already_exists') {
                        messages.push('Error: ya existe un movimiento con ese nombre.')
                    }
                    else if(response.errors[error].code == 'error_movement_prefix_already_exists') {
                        messages.push('Error: ya existe un movimiento con ese prefijo.')
                    }

                    else if(response.errors[error].code == 'error_added_movement') {
                        messages.push('Error: Se ha producido un error al intentar guarda la información.')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }
                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }

            else if(response.sucess && !response.data) {
                console.log('Se  perdio la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido intente más tarde.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        deleteMovement: async ({ commit, rootGetters}, payload) => {
            const query = {
                "id": payload.id
            }

            const custom_headers = rootGetters.getHeaders
    
            const response = await sendRequest('catalog/movement/inactive', 'DELETE', null, null, query, custom_headers) 
    
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Movimiento eliminado con exito..',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
    
            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                commit('setAlert', {
                    open: true, 
                    message: 'Un error ha ocurrido intente más tarde.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
    
            else if(response.sucess && !response.data) {
                console.log('Se  perdio la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido intente más tarde.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        }
    },
    
}