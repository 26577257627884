import sendRequest from '../../libs/sendRequest'

export const companies = {
    namespaced: true,
    state: {
        companies: [],
        company: {},
        error: null,
        total_pages: null,
        total_rows: null
    },
    getters: {
        getCompanies: state => {
            return state.companies
        },
        getCompany: state => {
            return state.company
        },
        getError: state => {
            return state.error
        },
        getTotalPages: state => {
            return state.total_pages
        },
        getTotalRows: state => {
            return state.total_rows
        }
    },
    mutations: {
        setCompanies: (state, payload) => {
            state.companies = payload
        },
        setCompany: (state,payload) => {
            state.company = payload
        },
        setError: (state, payload) => {
            state.error = payload
        },
        setTotalPages: (state, payload) => {
            state.total_pages = payload
        },
        setTotalRows: (state, payload) => {
            state.total_rows = payload
        }
    },
    actions: {
        listCompanies: async (context, payload) => {
            const custom_headers = context.rootGetters.getHeaders

            const query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: payload.search,
                order_by: 'id_business',
                order_asc: false,
            }

            const response = await sendRequest('catalog/businessinformation/list', 'GET', null, null, query, custom_headers)
            if(response.success && response.data) {
                if(query.datatable) {
                    context.commit('setCompanies', response.data.list.result ?? [])
                    context.commit('setTotalPages', response.data.list.total_pages ?? 0)
                    context.commit('setTotalRows', response.data.list.total_rows ?? 0)
                }

                else {
                    context.commit('setComapanies', response.data.list ?? [])
                }

                context.commit('setError', false)
            }
            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                context.commit('setError', true)
            }
            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API')
            }
        },
        viewCompany: async (context, query) => {

            const custom_headers = context.rootGetters.getHeaders


            const response = await sendRequest('catalog/businessinformation/view', 'GET', null, null, query, custom_headers)
            if(response.success && response.data) {
                context.commit('setCompany', response.data.view)
            }
            else if(!response.success && response.errors){
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }
            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API')
            }
        },
        insertCompany: async ({ commit, rootGetters}, body) => {

            const custom_headers = rootGetters.getHeaders


            const response = await sendRequest('catalog/businessinformation/add', 'POST', null, body, null, custom_headers) 

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Información editada con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});

                commit('setError', false)
            }

            else if(!response.success && response.errors) {
                let messages = []

                for(let error in response.errors) {
                    if(response.errors[error].code === 'error_business_name_already_exist') {
                        messages.push('Error: ya existe una compañia con este nombre.')
                    }

                    else if(response.errors[error].code === 'error_business_email_already_exist') {
                        messages.push('Error: ya existe una compañia con este correo.')
                    }

                    else if(response.errors[error].code === 'error_business_rfc_already_exist') {
                        messages.push('Error: ya existe una compañia con este RFC.')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }
                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});

                commit('setError', false)
            }

            else if(response.success && !response.data) {
                console.log('Se  perdio la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        editCompany: async ({commit, rootGetters}, payload) => {

            const custom_headers = rootGetters.getHeaders


            const response = await sendRequest('catalog/businessinformation/edit', 'PUT', null, payload.body, payload.query, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Compañia editada con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});

                commit('setError', false)
            }

            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code === 'error_business_name_already_exist') {
                        messages.push('Error: ya existe una compañia con este nombre.')
                    }

                    else if(response.errors[error].code === 'error_business_email_already_exist') {
                        messages.push('Error: ya existe una compañia con este correo.')
                    }

                    else if(response.errors[error].code === 'error_business_rfc_already_exist') {
                        messages.push('Error: ya existe una compañia con este RFC.')
                    }
                }
                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});

                commit('setError', true)
            }

            else if(response.success && !response.data) {
                console.log('Se  perdio la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        deleteCompany: async ({commit, rootGetters}, payload) => {

            const custom_headers = rootGetters.getHeaders

    
            const response = await sendRequest('catalog/businessinformation/inactive', 'DELETE', null, null, payload, custom_headers)
    
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Información eliminada con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }
    
            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_cannot_delete_business') {
                        messages.push('Error: Error al intentar eliminar la información.')
                    }

                    else {
                        messages.push('Un error ha ocurrido, intente más tarde.')
                    }
                }
                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
    
            else if(response.success && !response.data) {
                console.log('Se  perdio la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        }
    } 
}