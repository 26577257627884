export default [
    {
        path: '/Caja',
        name: 'cashregister',
        meta: {
            layout: 'session'
        },
        component: () => import('../../views/Configuration/CashRegister.vue')
    },
    {
        path: '/Corte-de-caja',
        name: 'cashbox',
        meta: {
            layout: 'session'
        },
        component: () => import('../../views/Catalogues/Warehouses/Warehouses.vue')
    },
    {
        path: '/Empresa',
        name: 'business',
        meta: {
            layout: 'session'
        },
        component: () => import('../../views/Configuration/Business.vue')
    },
    {
        path: '/Etiquetas-impresas',
        name: 'printedlabels',
        meta: {
            layout: 'session'
        },
        component: () => import('../../views/Configuration/PrintedLabels.vue')
    },
    {
        path: '/Ticket',
        name: 'ticket',
        meta: {
            layout: 'session'
        },
        component: () => import('../../views/Configuration/Ticket.vue')
    }
]
