export default [
    {
        path: '/Inventarios',
        name: 'inventory',
        meta: {
            layout: 'session'
        },
        component: () => import('../../views/Inventory.vue')
    }
]
