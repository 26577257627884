import sendRequest from '../../libs/sendRequest'

export const warehouse = {
    namespaced: true,
    state: {
        warehouses: [],
        warehouse: {},
        error: null,
        total_pages: 0,
        total_rows: 0
    },
    getters: {
        getWarehouses: state => {
            return state.warehouses
        },
        getWarehouse: state => {
            return state.warehouse
        },
        getError: state => {
            return state.error
        },
        getTotalPages(state) {
            return state.total_pages
        },
        getTotalRows(state) {
            return state.total_rows
        }
    },
    mutations: {
        setWarehouses: (state, payload) => {
            state.warehouses = payload
        },
        setWarehouse: (state,payload) => {
            state.warehouse = payload
        },
        setError:(state, payload) => {
            state.error = payload
        },
        setTotalPages(state, payload) {
            state.total_pages = payload
        },
        setTotalRows(state, payload) {
            state.total_rows = payload
        }
    },
    actions: {
        listWarehouses: async ({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders

            const query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: payload.search,
                order_by: 'id_dc',
                order_asc: false,
            }

            const response = await sendRequest('catalog/distributioncenters/list', 'GET', null, null, query, custom_headers)
            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setWarehouses', response.data.list.result ?? [] )
                    commit('setTotalPages', response.data.list.total_pages ?? 0)
                    commit('setTotalRows', response.data.list.total_rows ?? 0) 
                }

                else {
                    commit('setWarehouses', response.data.list ?? [])
                }
 
                commit('setError', false)
            }
            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                commit('setError', true)
            }
            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API')
            }
        },
        viewWarehouse: async ({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders

            const query = {
                "id_dc": payload.distributor_id
            }

            const response = await sendRequest('catalog/distributioncenters/view', 'GET', null, null, query, custom_headers)
            if(response.success && response.data) {
                commit('setWarehouse', response.data.view)
            }
            else if(!response.success && response.errors){
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }
            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API')
            }
        },
        insertWarehouse: async ({commit, rootGetters}, body) => {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('catalog/distributioncenters/add', 'POST', null, body, null, custom_headers) 

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Almacén añadido con exito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    console.log(response.errors[error])
                    if( response.errors[error].code == 'error_store_not_found') {
                        messages.push('Error: sucursal no encontrada. Verifique los datos.')
                    }

                    else if( response.errors[error].code == 'error_store_inactive') {
                        messages.push('Error: la sucursal seleccionada se encuentra inactiva.')
                    }

                    else if( response.errors[error].code == 'error_store_has_already_been_used') {
                        messages.push('Error: la sucursal seleccionada ya se encuentra en uso.')
                    }

                    else if( response.errors[error].code == 'error_dc_name_already_exists') {
                        messages.push('Error: El nombre ya fue registrado anteriormente.')
                    }

                    else if( response.errors[error].code == 'error_dc_email_already_exists') {
                        messages.push('Error: el correo fue registrado con anterioridad.')
                    }

                    else if( response.errors[error].code == 'error_inserting_distribution_centers') {
                        messages.push('Error: error en interno, intente de nuevo más tarde')
                    }
                    else {
                        messages.push('Error: Un error ha ocurrido intente más tarde')
                    }
                }

                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }

            else if(response.sucess && !response.data) {
                console.log('Se  perdio la conexión con la API')
            }
        },
        editWarehouse: async ({ commit, rootGetters}, data) => {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('catalog/distributioncenters/edit', 'PUT', null, data.body, data.query, custom_headers) 

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Almacén editado con éxito',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    console.log(response.errors[error])
                    if( response.errors[error].code == 'error_store_not_found') {
                        messages.push('Error: sucursal no encontrada. Verifique los datos.')
                    }

                    else if( response.errors[error].code == 'error_store_inactive') {
                        messages.push('Error: la sucursal seleccionada se encuentra inactiva.')
                    }

                    else if( response.errors[error].code == 'error_distribution_center_not_found') {
                        messages.push('Error: el almacén no se ha encontrado..')
                    }

                    else if( response.errors[error].code == 'error_store_has_already_been_used') {
                        messages.push('Error: la sucursal seleccionada ya se encuentra en uso.')
                    }

                    else if( response.errors[error].code == 'error_dc_name_already_exists') {
                        messages.push('Error: El nombre ya fue registrado anteriormente.')
                    }

                    else if( response.errors[error].code == 'error_dc_email_already_exists') {
                        messages.push('Error: el correo fue registrado con anterioridad.')
                    }

                    else if( response.errors[error].code == 'error_updating_distribution_center') {
                        messages.push('Error: error en interno, intente de nuevo más tarde')
                    }
                    else {
                        messages.push('Error: Un error ha ocurrido intente más tarde')
                    }
                }

                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }

            else if(response.sucess && !response.data) {
                console.log('Se  perdio la conexión con la API')
            }
        },
        deleteWarehouse: async ({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders

            const query = {
                "id_dc": payload.warehouse_id
            }
    
            const response = await sendRequest('catalog/distributioncenters/inactive', 'DELETE', null, null, query, custom_headers) 
    
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Almacén eliminado con éxito',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }
    
            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_distribution_center_not_found') {
                        messages.push('Error: Almacén no encontrado.')
                    }

                    else if(response.errors[error].code == 'error_distribution_center_already_inactive') {
                        messages.push('Error: Almacén no eliminado previamente.')
                    }

                    else if(response.errors[error].code == 'error_inactivating_distribution_center') {
                        messages.push('Error: Operación no realizada con éxito.')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde')
                    }
                }

                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
    
            else if(response.success && !response.data) {
                console.log('Se  perdio la conexión con la API')
            }
        }
    }
}