import sendRequest from '../../libs/sendRequest'

export const employees = {
    namespaced: true,
    state: {
        employees: [],
        employee: {},
        error: null,
        total_pages: 0,
        total_rows: 0
    },
    getters: {
        getEmployees: state => {
            return state.employees
        },
        getEmployee: state => {
            return state.employee
        },
        getError: state => {
            return state.error
        },
        getTotalPages(state) {
            return state.total_pages
        },
        getTotalRows(state) {
            return state.total_rows
        }
    },
    mutations: {
        setEmployees: (state, payload) => {
            state.employees = payload
        },
        setEmployee: (state,payload) => {
            state.employee = payload
        },
        setError: (state, payload) => {
            state.error = payload
        },
        setTotalPages(state, payload) {
            state.total_pages = payload
        },
        setTotalRows(state, payload) {
            state.total_rows = payload
        }
    },
    actions: {
        listEmployees: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders

            const query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: payload.search,
                order_by: 'id_employee',
                order_asc: false,
            }

            const response = await sendRequest('catalog/employees/list', 'GET', null, null, query, custom_headers)
            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setEmployees', response.data.list.result  ?? [])
                    commit('setTotalPages', response.data.list.total_pages ?? 0)
                    commit('setTotalRows', response.data.list.total_rows ?? 0)  
                }

                else {
                    commit('setEmployees', response.data.list  ?? [])
                }
                
                commit('setError', false)
            }
            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                commit('setError', true)
            }
            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API')
            }
        },
        viewEmployee: async ({commit, rootGetters}, payload) => {

            const custom_headers = rootGetters.getHeaders

            const query = {
                "id_employee": payload.employee_id
            }

            const response = await sendRequest('catalog/employees/view', 'GET', null, null, query, custom_headers)
            if(response.success && response.data) {
                commit('setEmployee', response.data.view)
            }

            else if(!response.success && response.errors){
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            else if(response.success && !response.data) {
                console.log('se perdio la conexión con la API')
            }

        },
        insertEmployee: async ({commit, rootGetters}, body) => {

            const custom_headers = rootGetters.getHeaders
            
            const response = await sendRequest('catalog/employees/add', 'POST', null, body, null, custom_headers) 

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Empleado creado con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_employee_full_name_already_exists') {
                        messages.push('Error: Ya existe un empleado registrado con ese nombre.')
                    }

                    else if(response.errors[error].code == 'error_employee_rfc_already_exists') {
                        messages.push('Error: Ya existe un empleado registrado con ese RFC.')
                    }

                    else if(response.errors[error].code == 'error_inserting_employee') {
                        messages.push('Error: Error al intentar registrar el empleado.')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }
                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }

            else if(response.success && !response.data) {
                console.log('Se  perdio la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        editEmployeeRole: async ({commit, rootGetters}, data) => {

            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('catalog/employees/edit', 'PUT', null, data.body, data.query, custom_headers) 
            
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Empleado editado con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_employee_full_name_already_exists') {
                        messages.push('Error: ya existe un empleado registrado con ese nombre.')
                    }

                    else if(response.errors[error].code == 'error_employee_rfc_already_exists') {
                        messages.push('Error: ya existe un empleado registrado con ese RFC.')
                    }

                    else if(response.errors[error].code == 'error_updating_employee') {
                        messages.push('Error: Error al intentar editar la información.')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }
                commit('setAlert', {
                    open: true, 
                    message: 'Empleado inactivado con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            else if(response.success && !response.data) {
                console.log('Se  perdio la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        deleteEmployee: async ({commit, rootGetters}, payload) => {

            const custom_headers = rootGetters.getHeaders

            const query = {
                "id_employee": payload.id_employee
            }
    
            const response = await sendRequest('catalog/employees/inactive', 'DELETE', null, null, query, custom_headers) 
    
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Empleado inactivado con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }
    
            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_inactivating_employee' ) {
                        messages.push('Error: error al intentar eliminar el empleado.')
                    }
                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }
                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
    
            else if(response.sucess && !response.data) {
                console.log('Se  perdio la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        }
    }
}