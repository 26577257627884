import sendRequest from "../../libs/sendRequest"

export  const products_module = {
    namespaced: true,
    state: { 
        products: [],
        product: {},
        error: null,
        total_pages: 0,
        total_rows: 0
    },
    getters: {
        getProducts(state) {
            return state.products
        },
        getProduct(state) {
            return state.product
        },
        getError(state) {
            return state.error
        },
        getTotalPages(state) {
            return state.total_pages
        },
        getTotalRows(state) {
            return state.total_rows
        }
    },
    mutations: {
        setProducts(state, payload) {
            state.products = payload
        },
        setProduct(state,payload) {
            state.product = payload
        },
        setError(state, payload) {
            state.error = payload
        },
        setTotalPages(state, payload) {
            state.total_pages = payload
        },
        setTotalRows(state, payload) {
            state.total_rows = payload
        }
    },
    actions: {
        async listProducts({ commit, rootGetters}, payload) {
            
            const custom_headers = rootGetters.getHeaders

            const query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: payload.search,
                order_by: 'creation',
                order_asc: false,
            }

            const response = await sendRequest('catalog/products/list', 'GET', null, null, query, custom_headers)
            //console.log('INVENTORY LIST:',response)
            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setProducts', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages ?? 0)
                    commit('setTotalRows', response.data.list.total_rows ?? 0)    
                }

                else {
                    commit('setProducts', response.data.list ?? [])
                }
                commit('setError', false)
            }

            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(error)
                }
                commit('setError', true)
            }

            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
            }
        },
        async viewProduct ({commit, rootGetters}, payload) {

            const custom_headers = rootGetters.getHeaders

            const query = {
                'id_product': payload.id_product
            }

            const response = await sendRequest('catalog/products/view', 'GET', null, null, query, custom_headers)
            
            if(response.success && response.data) {
                commit('setProduct', response.data.view)
            }
            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(error)
                }
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
            }
        },
        async insertProduct({commit, rootGetters}, body) {

            const custom_headers = rootGetters.getHeaders
            
            const response = await sendRequest('catalog/products/add', 'POST', null, body, null, custom_headers)
            
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Producto añadido con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }
            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error] == 'error_product_already_exists') {
                        messages.push('Error: nombre de producto registrado con anterioridad.')
                    }

                    else if(response.errors[error] == 'error_inserting_product') {
                        messages.push('Error: Error al intentar guarda la información.')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }

                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
                
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        async editProduct ({ commit, rootGetters}, data) {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('catalog/products/edit', 'PUT', null, data.body, data.query, custom_headers)
            //console.log('INVENTORY EDIT:', response)
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Producto editado con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error] == 'error_product_already_exists') {
                        messages.push('Error: nombre producto registrado con anterioridad')
                    }

                    else if(response.errors[error] == 'error_updating_product') {
                        messages.push('Error: Fallo al procesar editar la información.')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }

                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        async deleteProduct ({ commit, rootGetters}, payload) {

            const custom_headers = rootGetters.getHeaders

            const query = {
                'id_product': payload.id_product
            }

            const response = await sendRequest('catalog/products/inactive', 'DELETE', null, null, query, custom_headers)
            //console.log('INVENTORY DELETE:', response)
            if(response.success && response.data) {
                console.log('eliminado exitosamente')
                commit('setAlert', {
                    open: true, 
                    message: 'Producto eliminado con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }
            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_inactivating_product') {
                        messages.push('Error: Un error ha ocurrido al procesar la operación, intente más tarde.')
                    }

                    else {
                        messages.push('Un error a ocurrido intente más tarde.')
                    }
                }

                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido intente más tarde.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        }
    }
}