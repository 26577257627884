import sendRequest from "../../libs/sendRequest"

export  const inventories_assigns_module = {
    namespaced: true,
    state: { 
        inventoriesAssigns: [],
        inventoryAssign: {},
        error: null
    },
    getters: {
        getInventoriesAssigns(state) {
            return state.inventoriesAssigns
        },
        getInventoryAssign(state) {
            return state.inventoryAssign
        },
        getError(state) {
            return state.error
        }
    },
    mutations: {
        setInventoriesAssigns(state, payload) {
            state.inventoriesAssigns = payload
        },
        setInventoryAssign(state,payload) {
            state.inventoryAssign = payload
        },
        setError(state, payload) {
            state.error = payload
        }
    },
    actions: {
        async listInventoriesAssigns(context) {
            
            const custom_headers = context.rootGetters.getHeaders

            const response = await sendRequest('catalog/inventoriesassing/list', 'GET', null, null, null, custom_headers)
            //console.log('INVENTORY LIST:',response)
            if(response.success && response.data) {
                context.commit('setInventoriesAssigns', response.data.list)
                context.commit('setError', false)
            }

            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(error)
                }
                context.commit('setError', true)
            }

            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
            }
        },
        async viewInventoryAssign (context, payload) {

            const custom_headers = context.rootGetters.getHeaders

            const query = {
                'id_inventory': payload.id
            }
            const response = await sendRequest('catalog/inventoriesassing/view', 'GET', null, null, query, custom_headers)
            //console.log('INVENTORY VIEW': response)
            if(response.success && response.data) {
                context.commit('setInventoryAssign', response.data.view)
            }
            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(error)
                }
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
            }
        },
        async insertInventoryAssign({ commit, rootGetters}, payload) {

            const custom_headers = rootGetters.getHeaders

            const body = {
                "id_inventory": payload.id_inventory,
                "id_distribution_center": payload.id_distribution_center,
                "id_store": payload.id_store,
                "quantity": payload.quantity
            }
            
            const query = {
                "id_product": payload.id_product
            }

            const response = await sendRequest('catalog/inventoriesassing/add', 'POST', null, body, query, custom_headers)
            //console.log('INVENTORY ADD', response)
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Asignación creada con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }
            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_not_insert_inventory_assing') {
                        messages.push('Error: Error al intentar editar la información.')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }
                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
            else if(response.success && response.data === null) {
                
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        async editInventoryAssign ({commit, rootGetters}, payload) {
            const custom_headers = rootGetters.getHeaders

            const body = {
                "id_inventory": payload.id_inventory,
                "id_distribution_center": payload.id_distribution_center,
                "id_store": payload.id_store,
                "quantity": payload.quantity,
                "active": payload.active
            }
            

            const query = {
                'id_inventory_assing': payload.id_inventory_assing
            }

            const response = await sendRequest('catalog/inventoriesassing/edit', 'PUT', null, body, query, custom_headers)
            //console.log('INVENTORY EDIT:', response)
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Elemento editado con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_not_insert_inventory_assing') {
                        messages.push('Error: Error al intentar editar la información.')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }
                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido intente nuevamente.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        async deleteInventoryAssign ({commit, rootGetters}, payload) {

            const custom_headers = rootGetters.getHeaders

            const query = {
                'id_inventory_assign': payload.inventory_assign_id
            }

            const response = await sendRequest('catalog/inventoriesassing/inactive', 'DELETE', null, null, query, custom_headers)
            //console.log('INVENTORY DELETE:', response)
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Elemento eliminado con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }
            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                commit('setAlert', {
                    open: true, 
                    message: 'Un error ha sucedido intente más tarde.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
            else if(response.success && response.data === null) {
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        }
    }
}