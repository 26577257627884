import sendRequest from "../../libs/sendRequest"

export  const category = {
    namespaced: true,
    state: { 
        categories: [],
        category: {},
        error: null,
        total_pages: 0,
        total_rows: 0
    },
    getters: {
        getCategories(state) {
            return state.categories
        },
        getCategory(state) {
            return state.category
        },
        getError(state) {
            return state.error
        },
        getTotalPages(state) {
            return state.total_pages
        },
        getTotalRows(state) {
            return state.total_rows
        }
    },
    mutations: {
        setCategories(state, payload) {
            state.categories = payload
        },
        setCategory(state,payload) {
            state.category = payload
        },
        setError(state, payload) {
            state.error = payload
        },
        setTotalPages(state, payload) {
            state.total_pages = payload
        },
        setTotalRows(state, payload) {
            state.total_rows = payload
        }
    },
    actions: {
        async listCategories ({ commit, rootGetters}, payload) {
            
            const custom_headers = rootGetters.getHeaders

            const query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: payload.search,
                order_by: 'id_category',
                order_asc: false,
            }

            const response = await sendRequest('catalog/categories/list', 'GET', null, null, query, custom_headers)
            
            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setCategories', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages ?? 0)
                    commit('setTotalRows', response.data.list.total_rows ?? 0) 
                }

                else {
                    commit('setCategories', response.data.list ?? [])
                }
                
                commit('setError', false)
            }
            else if(!response.success && respose.errors) {
                for(let error in response.errors) {
                    console.log(error)
                }
                commit('setError', true)
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
            }
        },
        async viewCategory ({ commit, rootGetters}, payload) {

            const custom_headers = rootGetters.getHeaders

            const query = {
                'id_category': payload.id
            }
            const response = await sendRequest('catalog/categories/view', 'GET', null, null, query, custom_headers)
            //console.log('CATEGORY VIEW': response)
            if(response.success && response.data) {
                commit('setCategory', response.data.view)
            }
            else if(!response.succes && respose.errors) {
                for(let error in response.errors) {
                    console.log(error)
                }
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
            }
        },
        async insertCategory ({ commit, rootGetters}, body) {

            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('catalog/categories/add', 'POST', null, body, null, custom_headers)
            //console.log('CATEGORY ADD', response)
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Categoria añadida correctamente.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }
            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_category_already_exists') {
                        messages.push('Error: Ya existe una categoria con ese nombre.');
                    }

                    else if(response.errors[error].code == 'error_inserting_category') {
                        messages.push('Error: Error al guardar la información');
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                    
                }
                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        async editCategory ({ commit, rootGetters}, data) {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('catalog/categories/edit', 'PUT', null, data.body, data.query, custom_headers)
            //console.log('CATEGORY EDIT:', response)
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Categoria editada correctamente.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            else if(!response.success && response.errors) {
                let messages = []

                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_category_already_exists') {
                        messages.push('Error: Ya existe una categoria con ese nombre.')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }

                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        async deleteCategory ({ commit, rootGetters}, payload) {

            const custom_headers = rootGetters.getHeaders

            const query = {
                'id_category': payload.id
            }

            const response = await sendRequest('catalog/categories/inactive', 'DELETE', null, null, query, custom_headers)
            //console.log('CATEGORY DELETE:', response)
            if(response.success && response.data) {
                console.log('eliminado exitosamente')
                commit('setAlert', {
                    open: true, 
                    message: 'Categoria eliminada con éxito',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }
            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_inactivating_category') {
                        messages.push('Error: Error al intentar eliminar la categoria.')
                    }

                    else {
                        messages.push('un error ha ocurrido intente más tarde')
                    }
                }

                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        }
    }
}