import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

global.CONFIG = require('./page.config.js')

// BOOSTRAP-VUE
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import './assets/scss/custom-bootstrap.scss'

// Make BSidebar available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// Lodash
import _ from 'lodash'
global._ = _

// Jquery
import jQuery from 'jquery'
global.$ = jQuery

// Global components
// import { Alert, Input, Checkbox, GroupCheckbox, Tab, Chip, Table, Chart, Spinner, Modal } from './components/index.js'
import { Input, Select, Checkbox, Table, Modal, Stepper, Loader, Alert, Accordion } from './components/index.js'
Vue.component('ki-alert', Alert)
Vue.component('ki-input', Input)
Vue.component('ki-select', Select)
Vue.component('ki-checkbox', Checkbox)
Vue.component('ki-accordion', Accordion)
// Vue.component('ki-group-checkbox', GroupCheckbox)
// Vue.component('ki-tab', Tab)
// Vue.component('ki-chip', Chip)
Vue.component('ki-table', Table)
// Vue.component('ki-chart', Chart)
// Vue.component('ki-spinner', Spinner)
Vue.component('ki-modal', Modal)
Vue.component('ki-stepper', Stepper)
Vue.component('ki-loader', Loader)

// LIBS

// Request
import _Request from './libs/sendRequest.js'
Vue.prototype.$sendRequest = _Request


// TEMPLATES
const DEFAULT = () => import ('TEMPLATES/default')
Vue.component('default', DEFAULT)
const SESSION = () => import ('TEMPLATES/session')
Vue.component('session', SESSION)

Vue.config.productionTip = false

//Mixins
Vue.mixin({
    methods: {
        setAccess(access, menu_title, submenu_title, module_title) {
            //console.log('Access_obj:', access)
            try {
                let sale_menu = access.find( menu => menu.name == menu_title);
                let submenu = sale_menu.submenu.find( submenu_obj => submenu_obj.name == submenu_title);
                const sale_module = submenu.modules.find( modulo => modulo.name == module_title);
                    
                let actions = sale_module.actions.map( action => {
                    return {
                        name: action.name,
                        has_permission: action.active
                    }
                })

                return actions
            }

            catch(e) {
                console.log('error:', e)
            }
        },
        formatAmount(value, decimals = 2) {
            return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals)
        },
        dateParser(millisecons) {
            const date = new Date(millisecons).toLocaleDateString().replaceAll('/','-');
            let date_array = date.split('-');
            date_array = date_array.reverse();
            if(date_array[1].length === 1) {
                date_array[1] = `0${date_array[1]}`;
            }

            if(date_array[2].length === 1) {
                date_array[2] = `0${date_array[2]}`;
            }

            const final_date = date_array.join('-');
            return final_date;
        }
    }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
