import sendRequest from "../../libs/sendRequest"

export  const users = {
    namespaced: true,
    state: { 
        users: [],
        user: {},
        error: null,
        total_pages: 0,
        total_rows: 0
    },
    getters: {
        getUsers(state) {
            return state.users
        },
        getUser(state) {
            return state.user
        },
        getError(state) {
            return state.error
        },
        getTotalPages(state) {
            return state.total_pages
        },
        getTotalRows(state) {
            return state.total_rows
        }
    },
    mutations: {
        setUsers(state, payload) {
            state.users = payload
        },
        setUser(state,payload) {
            state.user = payload
        },
        setError(state, payload) {
            state.error = payload
        },
        setTotalPages(state, payload) {
            state.total_pages = payload
        },
        setTotalRows(state, payload) {
            state.total_rows = payload
        }
    },
    actions: {
        async listUsers ({ rootGetters, commit}, payload) {
            
            const custom_headers = rootGetters.getHeaders
            console.log(payload)
            const query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: payload.search,
                order_by: 'id_user',
                order_asc: false,
            }

            const response = await sendRequest('core/users/list', 'GET', null, null, query, custom_headers)

            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setUsers', response.data.list.result ?? [])
                }

                else {
                    commit('setUsers', response.data.list ?? [])
                }

                commit('setTotalPages', response.data.list.total_pages)
                commit('setTotalRows', response.data.list.total_rows)   
                commit('setError', false)
            }

            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                commit('setError', true)
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
            }
        },
        async viewUser (context, payload) {

            const custom_headers = context.rootGetters.getHeaders

            const query = {
                'id_user': payload.id_user
            }

            const response = await sendRequest('core/users/view', 'GET', null, null, query, custom_headers)
            console.log('response', response)
            if(response.success && response.data) {
                context.commit('setUser', response.data.view)
            }
            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(error)
                }
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
            }
        },
        async insertUser ({commit, rootGetters}, payload) {

            const custom_headers = rootGetters.getHeaders

            const body = {
                "id_employee": payload.id_employee,
	            "id_user_role": payload.id_user_role,
	            "username": payload.username,
	            "password": payload.password       
            }


            const response = await sendRequest('core/users/add', 'POST', null, body, null, custom_headers)
            //console.log('USER ADD', response)
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Usuario añadido con éxito',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }
            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    console.log(response.errors[error])
                    if(response.errors[error].code == 'error_session_isnt_administrator') {
                        messages.push('Error: El usuario no es administrador.')
                    }

                    else if(response.errors[error].code == 'error_employee_not_found') {
                        messages.push('Error: Empleado no encontrado.')
                    }

                    else if(response.errors[error].code == 'error_employee_inactive') {
                        messages.push('Error: Empleado inactivo.')
                    }

                    else if(response.errors[error].code == 'error_user_role_not_found') {
                        messages.push('Error: Rol de usuario no encontrado.')
                    }

                    else if(response.errors[error].code == 'error_user_role_inactive') {
                        messages.push('Error: Rol de usuario inactivo.')
                    }

                    else if(response.errors[error].code == 'error_user_employee_already_exists') {
                        messages.push('Error: El usuario ya existe.')
                    }

                    else if(response.errors[error].code == 'error_user_username_already_exists') {
                        messages.push('Error: El nombre de usuario ya existe.')
                    }
                    else {
                        messages.push('Error: Un error ha ocurrido intente nuevamente')
                    }
                }

                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
                
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Error: Un error ha ocurrido intente más tarde',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        async editUser ({commit, rootGetters}, payload) {
            const custom_headers = rootGetters.getHeaders

            const body = {
                "username": payload.username,
                "id_user_role": payload.id_user_role
            }

            const query = {
                id_user: payload.id_user
            }

            const response = await sendRequest('core/users/edit', 'PUT', null, body, query, custom_headers)
            
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Usuario editado con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }
            else if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    console.log(response.errors[error])
                    if(response.errors[error].code == 'error_session_isnt_administrator') {
                        messages.push('Error: El usuario no es administrador.')
                    }

                    else if(response.errors[error].code == 'error_employee_not_found') {
                        messages.push('Error: Empleado no encontrado.')
                    }

                    else if(response.errors[error].code == 'error_employee_inactive') {
                        messages.push('Error: Empleado inactivo.')
                    }

                    else if(response.errors[error].code == 'error_user_role_not_found') {
                        messages.push('Error: Rol de usuario no encontrado.')
                    }

                    else if(response.errors[error].code == 'error_user_role_inactive') {
                        messages.push('Error: Rol de usuario inactivo.')
                    }

                    else if(response.errors[error].code == 'error_user_employee_already_exists') {
                        messages.push('Error: El usuario ya existe.')
                    }

                    else if(response.errors[error].code == 'error_user_username_already_exists') {
                        messages.push('Error: El nombre de usuario ya existe.')
                    }

                    else if(response.errors[error].code == 'error_updating_user') {
                        messages.push('Error: Edición fallida.')
                    }

                    else {
                        messages.push('Error: Un error ha ocurrido intente nuevamente')
                    }
                }

                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Error: Un error ha ocurrido intente más tarde',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        async changeUserPassword ({ commit, rootGetters }, payload) {

            const body = {
                password: payload.password
            }
            const query = {
                id_user: payload.id_user
            }
            
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('core/users/password_change', 'PUT', null, body, query, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Contraseña actualizada con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }
            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                commit('setAlert', {
                    open: true, 
                    message: 'Error: Un error ha ocurrido intente más tarde.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Error: Un error ha ocurrido intente más tarde.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        async deleteUser ({ commit, rootGetters}, payload) {

            const custom_headers = rootGetters.getHeaders

            const body = {
                password: payload.password
            }
            const query = {
                'id_user': payload.id_user
            }

            const response = await sendRequest('core/users/inactive', 'DELETE', null, body, query, custom_headers)
            
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Usuario eliminado con éxito',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }
            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                commit('setAlert', {
                    open: true, 
                    message: 'Error: Un error ha ocurrido intete más tarde',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
            else if(response.success && response.data === null) {
                console.log('se ha perdido la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Error: Un error ha ocurrido intete más tarde',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        }
    }
}