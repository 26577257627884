<template>
    <section style="border: solid 1px #DCDDE0;">
        <div :class="`scroll table-responsive m-0 ${(!no_pagination) ? 'mb-2 pb-1' : ''}`">
            <table class="table p-0 m-0">
                <thead>
                    <tr>
                        <th :class="`bg-${color_headers} text-${color_headers_text} border-${border_headers} text-left p-2`" 
                            scope="col" 
                            v-for="(header, index_header) in get_headers" 
                            :key="`${index_header}_header`"
                            v-if="allow_key_headers.includes(header)"
                            :width="findWidth(header)"
                            >

                                {{ findLabel(header) }}
                        </th>

                        <!-- Missing Headers -->
                        <th :class="`bg-${color_headers} text-${color_headers_text} border-${border_headers} text-left p-2`" 
                            scope="col" 
                            v-for="(m_header, index_m_header) in missing_headers" 
                            :key="`${index_m_header}_missing_header`"
                            :width="findWidth(m_header)">

                                {{ m_header }}
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(data_item, data_index) in data" :key="data_index" 
                        :class="[`text-${color_body_text} text-left`, 
                        ((data_index % 2) && striped) ? `bg-${color_body}-dark` : `bg-${color_body}`]">

                        <td v-for="(item, index_item) in data_item" 
                            :key="`${index_item}_item`"
                            :class="`border-${border_body} p-1`" 
                            v-if="allow_key_headers.includes(index_item)">

                            <slot :name="index_item" 
                                v-bind:value="data_item[index_item]" 
                                v-bind:item="data_item">

                                    {{ item }}
                            </slot>
                        </td>

                        <td v-for="(m_header, index_m_header) in missing_headers" 
                            :key="`${index_m_header}_missing_item`"
                            :class="`border-${border_body}`">

                            <slot :name="m_header" 
                                v-bind:item="data_item">

                                    {{ m_header }}
                            </slot>
                        </td>

                    </tr>
                </tbody>
            </table>

            <div class="col-12 text-center border-secondary py-3" v-if="no_records">
                <span>No se encontraron registros</span>
            </div>
        </div>

        <div class="row" v-if="data.length > 0 && !no_pagination">
            <div class="col-12 d-flex justify-content-end">
                <button class="btn btn-outline-light btn-pill py-1 px-4 mx-1">
                    <!-- <img :src="icon_arrow_left" class=""> -->
                </button>

                <button class="btn btn-light btn-pill py-1 px-3 mx-1">
                    <span class="ad--description-sm">1</span>
                </button>

                <button class="btn btn-light btn-pill py-1 px-4 mx-1">
                    <!-- <img :src="icon_arrow_right" class=""> -->
                    >
                </button>
            </div>
        </div>
    </section>
</template>

<script> 
    // import icon_arrow_left from './../assets/images/invoice/arrow_left.png'
    // import icon_arrow_right from './../assets/images/invoice/arrow_right.png'

    // jQuery
    import $ from 'jquery'

    export default {
        name: 'ad-table',
        props: {
            // Data
            allow_headers: {
                type: Array,
                required: true,
                default: [],
            },
            data: {
                type: Array,
                required: true,
                default: [],
            },
            // Headers
            color_headers: {
                type: String,
                required: false,
                default: 'light',
            },
            color_headers_text: {
                type: String,
                required: false,
                default: 'black',
            },
            border_headers: {
                type: String,
                required: false,
                default: 'white',
            },
            // Body
            color_body: {
                type: String,
                required: false,
                default: 'success'
            },
            color_body_text: {
                type: String,
                required: false,
                default: 'black',
            },
            border_body: {
                type: String,
                required: false,
                default: 'white',
            },
            // Striped
            striped: {
                type: Boolean,
                required: false,
                default: false
            },
            // Pagination
            no_pagination: {
                type: Boolean,
                required: false,
                default: false  
            },
            no_records: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                // icon_arrow_left,
                // icon_arrow_right
            }
        },
        computed: {
            get_headers() {
                if(this.data !== null && this.data.length > 0) {
                    // this.$logEnv(Object.keys(this.data[0]))
                    return Object.keys(this.data[0])
                }
                return []
            },
            allow_key_headers() {
                let allow = []
                for(let i in this.allow_headers) {
                    if(_.isObject(this.allow_headers[i]) && !_.isNull(this.allow_headers[i].key) ) {
                        allow.push(this.allow_headers[i].key)
                    }
                    else {
                        allow.push(this.allow_headers[i])   
                    }
                }

                return allow
            },
            missing_headers() {
                let difference = this.allow_key_headers.filter(x => !this.get_headers.includes(x));
                return difference
            }
        },
        methods: {
            findLabel(key) {
                for(let i in this.allow_headers) {
                    if(_.isObject(this.allow_headers[i]) && !_.isNull(this.allow_headers[i].key)) {
                        if(this.allow_headers[i].key == key) {
                            return this.allow_headers[i].label    
                        }
                    }
                    else {
                        return this.allow_headers[i]
                    }
                }
            },
            findWidth(key) {
                for(let i in this.allow_headers) {
                    if(_.isObject(this.allow_headers[i]) && !_.isNull(this.allow_headers[i].key)) {
                        if(this.allow_headers[i].key == key) {
                            let w = this.allow_headers[i].width
                            return ((w !== null) ? w : '')
                        }
                    }
                    else {
                        let w = this.allow_headers[i]
                        return ((w !== null) ? w : '')
                    }
                }
            }

             
        }
    }
</script>

<style lang="scss">
    @import '../assets/scss/colors.scss';

    /*⊱⊱════════════════════ Table ════════════════════⊰⊰*/
    .table {
        width: 100%;
        //word-break: break-word;
        font-size: clamp(14px, 1.5vw, 16px);
    }

    .scroll::-webkit-scrollbar {
        height: 10px;
    }

    .scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(255,255,255,0.3);
        box-shadow: inset 0 0 6px rgba(255,255,255,0.3);
        border-radius: 10px;
    }

    .scroll::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(255,255,255,0.5);
        box-shadow: inset 0 0 6px rgba(255,255,255,0.5);
    }
</style>