import sendRequest from '../../libs/sendRequest'

export const lockbox_module = {
    namespaced: true,
    state: {
        lockbox: null,
        lockboxes: null,
        error: null,
        total_pages: null,
        last_page: false,
        total_rows: null
    },
    getters: {
        getLockbox(state) {
            return state.lockbox
        },
        getLockboxes(state) {
            return state.lockboxes
        },
        getError(state) {
            return state.error
        },
        getTotalPages(state) {
            return state.total_pages
        },
        getLastPage(state) {
            return state.last_page
        },
        getTotalrows(state) {
            return state.total_rows
        }
    },
    mutations: {
        setLockbox(state, payload) {
            state.lockbox = payload
        },
        setLockboxes(state, payload) {
            state.lockboxes = payload
        },
        setError(state, payload) {
            state.error = payload
        },
        setTotalPages(state, payload) {
            state.total_pages = payload
        },
        setLastPage(state, payload) {
            state.last_page = payload
        },
        setTotalRows(state, payload) {
            state.total_rows = payload
        }
    },
    actions: {
        async listLockboxes({commit, rootGetters}, payload) {
            const custom_headers = rootGetters.getHeaders;

            const query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: payload.search,
                order_by: 'creation',
                order_asc: false,
            }

            const response = await sendRequest('sale/reserve/list', 'GET', null, null, query, custom_headers)
        
            if(response.success && response.data) {
                if(query.datatable) {
                    commit('setLockboxes', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages ?? 0)
                    commit('setTotalRows', response.data.list.total_rows ?? 0)
                }

                else {
                    commit('setLockboxes', response.data.list ?? [])
                }

                commit('setError', false)
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se ha perdido la conexion con la API')
            }
        },
        async viewLockbox(context, payload) {
            const custom_headers = context.rootGetters.getHeaders;

            const query = {
                id_sale: payload.id_sale
            }

            const response = await sendRequest('sale/reserve/view', 'GET', null, null, query, custom_headers)

            if(response.success && response.data) {
                context.commit('setLockbox', response.data.view)
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se ha perdido la conexion con la API')
            }
        },
        async cancelLockbox({commit, rootGetters}, payload) {
            const custom_headers = rootGetters.getHeaders;

            const query = {
                id_sale: payload.id_sale
            }

            const response = await sendRequest('sale/reserve/cancel', 'DELETE', null, null, query, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Apartado cancelado con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_inventory_assign_not_found') {
                        messages.push('Error: Inventario no encontrado.')
                    }

                    else if(response.errors[error].code == 'error_updating_inventory_assign') {
                        messages.push('Error: Error al actualizar el inventario.')
                    }

                    else if(response.errors[error].code == 'error_updating_sale') {
                        messages.push('Error: Error al actualizar la venta.')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }

                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }

            if(response.success && !response.data) {
                console.log('Se ha perdido la conexion con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        async payment({commit, rootGetters}, payload) {
            const custom_headers = rootGetters.getHeaders

            const query = {
                id_sale: payload.id_sale
            }

            const body = payload.transaction

            const response = await sendRequest('sale/reserve/payment', 'POST', null, body, query, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Pago añadido con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_amount_exceeds_pending_payment') {
                        messages.push('Error: El pago excede la cantidad faltante.')
                    }

                    else if(response.errors[error].code == 'error_cashbox_not_open') {
                        messages.push('Error: no se detectó una caja abierta.')
                    }

                    else if(response.errors[error].code == 'error_inserting_sale_payment_detail') {
                        messages.push('Error: Error al procesar el pago, intente nuevamente.')
                    }

                    else if(response.errors[error].code == 'error_updating_sale') {
                        messages.push('Error: Error al actualizar la venta, intente más tarde.')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }
                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }

            if(response.success && !response.data) {
                console.log('se perdio la conexión con la API');
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido intente más tarde.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        }
    }
}