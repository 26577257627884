import sendRequest from "../../libs/sendRequest"

export  const products_discounts_module = {
    namespaced: true,
    state: { 
        products_discounts: [],
        product_discount: {},
        error: null
    },
    getters: {
        getProductDiscounts(state) {
            return state.products_discounts
        }
    },
    mutations: {
        setProductDiscounts(state, payload) {
            state.products_discounts = payload
        }
    },
    actions: {
        async setDiscounts(context, payload){
            const custom_headers = context.rootGetters.getHeaders

            const body = {
                id_products: payload.products,
                id_discount: payload.discount
            }

            const response = await sendRequest('catalog/productsdiscounts/add', 'POST', null, body, null, custom_headers)
            console.log('RESPONSE:', response)
            if(response.success && response.data) {
                commit('setAlert', {
                    open: true, 
                    message: 'Descuentos asignados con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            if (!response.success && response.errors){
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_discount_not_found') {
                        messages.push('Error: Descuento no encontrado.')
                    }

                    else if(response.errors[error].code == 'error_discount_not_found') {
                        messages.push('Error: No se permite asociar productos a descuentos inactivos.')
                    }

                    else if(response.errors[error].code == 'error_product_not_found') {
                        messages.push('Error: Producto no encontrado.')
                    }

                    else if(response.errors[error].code == 'error_inserting_product_discount' ||  response.errors[error].code == 'error_updating_product_discount' ) {
                        messages.push('Error: La operación no se pudo procesar con éxito.')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }
                }
                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }

            else if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido intente más tarde.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        },
        async listProductDiscounts(context, payload){
            const custom_headers = context.rootGetters.getHeaders

            // const query = {
            //     id_product_discount: payload.id_product_discount
            // }

            const response = await sendRequest('catalog/productsdiscounts/list', 'GET', null, null, null, custom_headers)

            if(response.success && response.data) {
                context.commit('setProductDiscounts', response.data.list)
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
            }

        },
        async deleteProductDiscount(context, payload) {
            const custom_headers = context.rootGetters.getHeaders

            const query = {
                id_product_discount: payload.id_product_discount
            }

            const response = await sendRequest('catalog/productsdiscounts/inactive', 'DELETE', null, null, query, custom_headers)

            if(response.success && response.data) {
                console.log('SUCCESS', response.data)
                commit('setAlert', {
                    open: true, 
                    message: 'Descuento de producto removido con éxito.',
                    variant: 'success',
                    text_color: 'white',
                }, { root: true});
            }

            if(!response.success && response.errors) {
                let messages = []
                for(let error in response.errors) {
                    if(response.errors[error].code == 'error_product_discount_not_found') {
                        messages.push('Error: Descuento no encontrado.')
                    }

                    else if(response.errors[error].code == 'error_product_discount_already_inactive') {
                        messages.push('Error: Descuento eliminado con anterioridad.')
                    }

                    else if(response.errors[error].code == 'error_inactivating_product_discount') {
                        messages.push('Error: La operación no se ha realizado correctamente.')
                    }

                    else {
                        messages.push('Un error ha ocurrido intente más tarde.')
                    }

                }

                commit('setAlert', {
                    open: true, 
                    message: messages,
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }

            if(response.success && !response.data) {
                console.log('Se ha perdido la conexión con la API')
                commit('setAlert', {
                    open: true, 
                    message: 'Un error de conexión ha ocurrido intente más tarde.',
                    variant: 'danger',
                    text_color: 'white',
                }, { root: true});
            }
        }
    }
}