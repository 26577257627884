import sendRequest from '../../libs/sendRequest'

export  const reports = {
    namespaced: true,
    state: {
        total_pages: null,
        total_rows: 0,
        collection: [],
        details: {}
    },
    getters: {
        getTotalPages:(state) => (state.total_pages),
        getTotalRows:(state) => (state.total_rows),
        getCollection:(state) => (state.collection),
        getDetails: (state) => (state.details)
    },
    mutations: {
        setTotalPages: (state, payload) => (state.total_pages = payload) ,
        setTotalRows: (state, payload) => (state.total_rows = payload),
        setCollection: (state, payload) => (state.collection = payload),
        setDetails: (state, payload) => (state.details = payload)
    },
    actions: {
        listCashboxHistory: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            const query = {
                datatable: true,
                page: payload.page,
                rows: payload.rows,
                search: payload.search,
                order_by: "id_cashbox",
                order_asc: true
            }

            const response = await sendRequest('report/cashbox/list', 'GET', null, null, query, custom_headers)

            if(response.success && response.data) {
                commit('setTotalPages', response.data.total_pages)
                commit('setTotalRows', response.data.total_rows)
                commit('setCollection', response.data.result ?? []) 
            }

            if(!response.success && response.errors) {
                for(let error in response.errors){
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdió la conexión con la API')
            }
        },
        listSalesHistory: async({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            const query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                date_init: payload.date_init,
                date_end: payload.date_end,
                id_client: payload.id_client,
                id_vendor: payload.id_vendor,
                id_category: payload.id_category,
                id_subcategory: payload.id_subcategory,
                id_product: payload.id_product,
                search: payload.search,
                order_by: "creation",
                order_asc: false
            }


            const response = await sendRequest('reports/sales', 'GET', null, null, query, custom_headers)

            if(response.success && response.data) {
                commit('setTotalPages', response.data.list.total_pages)
                commit('setTotalRows', response.data.list.total_rows)
                commit('setCollection', response.data.list.result ?? [])

                if(query.datatable) {
                    commit('setCollection', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages ?? 0)
                    commit('setTotalRows', response.data.list.total_rows ?? 0)
                }

                else {
                    commit('setCollection', response.data.list ?? [])
                }

            }

            if(!response.success && response.errors) {
                for(let error in response.errors){
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdió la conexión con la API')
            }
        },
        saleDetails: async({ commit, rootGetters}, id_sale) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('reports/view_sale', 'GET', null, null, { id_sale: id_sale}, custom_headers);

            if(response.success && response.data) {
                commit('setDetails', response.data.view ?? {}) 
            }

            if(!response.succes && response.errors) {
                for(let error in response.errors){
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdió la conexión con la API')
            }
        },
        listTransfersHistory: async({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders;

            const query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                date_init: payload.date_init,
                date_end: payload.date_end,
                search: payload.search,
                order_by: "id_history_transfer",
                order_asc: false,
                type: payload.type
            }


            const response = await sendRequest('reports/get_history_transfers', 'GET', null, null, query, custom_headers)

            if(response.success && response.data) {
                commit('setTotalPages', response.data.list.total_pages)
                commit('setTotalRows', response.data.list.total_rows)
                commit('setCollection', response.data.list.result ?? [])

                if(query.datatable) {
                    commit('setCollection', response.data.list.result ?? [])
                    commit('setTotalPages', response.data.list.total_pages ?? 0)
                    commit('setTotalRows', response.data.list.total_rows ?? 0)
                }

                else {
                    commit('setCollection', response.data.list ?? [])
                }

            }

            if(!response.success && response.errors) {
                for(let error in response.errors){
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('Se perdió la conexión con la API')
            }
        }
    }
}